import React from "react"
import { Accordion } from "react-bootstrap"

const MoreOptionsPanel = () => {
  return (
    <div className="col-lg-12 mb-3" id="more_panel">
      <Accordion defaultActiveKey="0" className="accordion-item panel panel-grey" alwaysOpen>
        <Accordion.Header>
          <i className="bi bi-three-dots me-2" /> More Options
        </Accordion.Header>
        <Accordion.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-12 mb-3 assetPrivacyContainer">
                  <div className="colHeading">
                    <strong>
                      <i className="bi bi-lock-fill"></i> Privacy
                    </strong>
                  </div>
                  <div className="dh-block-body">
                    <div className="mb-1">
                      <input
                        type="radio"
                        name="asset_privacy"
                        id="P1"
                        className="css-checkbox"
                        value="Private"
                      />
                      <label htmlFor="P1" className="css-radio-label">
                        <i className="bi bi-lock-fill"></i> Private to me
                      </label>
                    </div>
                    <div className="mb-1">
                      <input
                        type="radio"
                        name="asset_privacy"
                        id="P4"
                        className="css-checkbox"
                        value="PrivateToWorkroom"
                        checked="checked"
                      />
                      <label htmlFor="P4" className="css-radio-label">
                        <i className="bi bi-lock-fill"></i> Private to Workroom Members
                      </label>
                    </div>
                    <div className="mb-1">
                      <input
                        type="radio"
                        name="asset_privacy"
                        id="P2"
                        className="css-checkbox"
                        value="Logged-in Members"
                      />
                      <label htmlFor="P2" className="css-radio-label">
                        <i className="bi bi-binoculars-fill"></i> Visible to Logged-in Members, Not Public{" "}
                        <span data-bs-toggle="tooltip" title="i.e. Internal Company Directory">
                          <i className="bi bi-question-circle-fill"></i>
                        </span>
                      </label>
                    </div>
                    <div className="mb-1">
                      <input
                        type="radio"
                        name="asset_privacy"
                        id="P3"
                        className="css-checkbox"
                        value="Public"
                      />
                      <label htmlFor="P3" className="css-radio-label">
                        <i className="bi bi-globe"></i> Visible to Public{" "}
                        <span
                          data-bs-toggle="tooltip"
                          title="Public Company directory, Association Roster, etc"
                        >
                          <i className="bi bi-question-circle-fill"></i>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mb-3 assetPrivacyContainer">
                  <div className="colHeading">
                    <strong>
                      <i className="bi bi-gear-fill"></i> Permissions
                    </strong>
                  </div>
                  <div className="dh-block-body">
                    <div className="mb-1">
                      <input
                        type="radio"
                        name="groupPermissions"
                        id="Per1"
                        className="css-checkbox"
                        value="None"
                        checked="checked"
                      />
                      <label htmlFor="Per1" className="css-radio-label">
                        <i className="bi bi-lock-fill"></i> None. Only I can edit.
                      </label>
                    </div>
                    <div className="mb-1">
                      <input
                        type="radio"
                        name="groupPermissions"
                        id="Per2"
                        className="css-checkbox"
                        value="Opt-out Mode"
                      />
                      <label htmlFor="Per2" className="css-radio-label">
                        <i className="bi bi-binoculars-fill"></i> Opt-out Mode: People can withdraw anytime
                        but not join.
                      </label>
                    </div>
                    <div className="mb-1">
                      <input
                        type="radio"
                        name="groupPermissions"
                        id="Per3"
                        className="css-checkbox"
                        value="Social Mode"
                      />
                      <label htmlFor="Per3" className="css-radio-label">
                        <i className="bi bi-globe"></i> Social Mode: People can join or withdraw anytime.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-12 mb-3">
                  <div className="colHeading">
                    <strong>
                      <i className="bi bi-tags-fill"></i> Tags
                    </strong>
                  </div>
                  <div className="dh-block-body">
                    <input
                      type="text"
                      name="group_tags"
                      id="group_tags"
                      className="form-control"
                      value="#tag"
                    />
                  </div>
                </div>
                <div className="col-lg-12 mb-3">
                  <div className="colHeading">
                    <strong>
                      <i className="bi bi-file-image"></i> Featured Image
                    </strong>
                  </div>
                  <div className="dh-block-body">
                    <div className="folderIcon">
                      <img src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/no-image.jpg`} />
                    </div>
                    <button type="button" className="btn btn-default btnDocFormIconEdit">
                      <span data-bs-toggle="tooltip" data-placement="bottom" title="Edit folder image">
                        <i className="bi bi-pencil-fill"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Accordion.Body>
      </Accordion>
    </div>
  )
}

export default MoreOptionsPanel
