import * as React from "react"

import Layout from "../../Layout/layout"
import Seo from "../../seo"

import "../../../css/bootstrap-tagsinput.css"
import "../../../css/jquery.dataTables.min.css"
import "bootstrap-slider/dist/css/bootstrap-slider.css"
import "../../../css/advanced_tag_search.css"
import "../../../css/global_url_injector.css"
import "../../../css/jquery.datetimepicker.css"
import "../../../css/chat-dock.css"
import "../../../css/tasks.css"
import "../../../css/drive.css"
import "../../../css/accounting.css"
import "../../../css/workroom.css"
import TaskPanel from "./TaskPanel"
import GroupMembers from "./GroupMembersPanel"
import Calendar from "./CalendarPanel"
import FilesPanel from "./FilesPanel"
import MoreOptionsPanel from "./MoreOptionsPanel"
import WorkroomChatPanel from "./WorkroomChatPanel"
import WorkroomHeader from "./workroomHeader"

const workroomDetail = () => {
  return (
    <Layout>
      <Seo title="folderDetail" />
      {/*Container Main start*/}
      <div id="workroomTemplate">
        <div id="right-section" class="h-100">
          <div class="row g-0">
            <div class="col" id="page-urls">
              <div class="row">
                <div class="col-lg-6 contactGroupViewLeftSection">
                  <WorkroomHeader></WorkroomHeader>

                  <div class="row g-0">
                    <div class="col-lg-12 mb-3">
                      <div class="row g-0">
                        {/*Tasks & Projects*/}
                        {/*<TaskPanel></TaskPanel>*/}

                        {/*Group Members*/}
                        <GroupMembers></GroupMembers>

                        {/*Calendar*/}
                        {/*<Calendar></Calendar>*/}

                        {/*Files*/}
                        <FilesPanel></FilesPanel>

                        {/*More Options*/}
                        <MoreOptionsPanel></MoreOptionsPanel>

                        <div class="col-lg-12 text-center">
                          <button type="button" class="btn btn-primary btn-lg">
                            <i class="bi bi-check2" /> Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <WorkroomChatPanel></WorkroomChatPanel>
              </div>
            </div>
          </div>
        </div>
        {/*Container Main end*/}
      </div>
    </Layout>
  )
}

export default workroomDetail
