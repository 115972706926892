import React, { useState } from "react"
import { Accordion } from "react-bootstrap"

const WorkroomHeader = () => {
  const [headerOn, setheaderOn] = useState("none")
  const headerOpen = () => {
    let detals = headerOn == "none" ? "block" : "none"
    setheaderOn(detals)
  }
  return (
    <React.Fragment>
      <div className="row g-0">
        <div className="col-sm-12 mb-3">
          <div className="pageTitle_Editor">
            <a href="drive.html" className="goBack">
              <i className="bi bi-arrow-90deg-left" />
            </a>
            <button className="CD_UserType isHuman" data-bs-toggle="tooltip" title="Workroom">
              <i className="bi bi-house-door-fill" />
            </button>
            <input
              type="text"
              className="form-control"
              placeholder="Workroom Name"
              value="Amitabh Bachchan Workroom"
            />
          </div>
        </div>
      </div>
      <div className="row g-0">
        <div className="col-lg-12 mb-3 CGDetailsContainer">
          <Accordion defaultActiveKey="0" alwaysOpen>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <div className="row g-0">
                  <div className="col-lg-12">
                    <div className="workroomUserInfoBox">
                      <div className="workroomUserInfoBoxImg">
                        <img
                          src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/bachchan-amitabh-image.jpg`}
                        />
                        <span className="userStarToggle">
                          <input type="checkbox" id="userStarToggle1" checked />
                          <label htmlFor="userStarToggle1" className="checkmark"></label>
                        </span>
                      </div>
                      <div>
                        <div>
                          <span className="fs-4 fw-bold">Amitabh Bachchan (Home Buyer)</span>
                          <a
                            className="btnworkroomUserEditContact ms-2"
                            href="contact_detail.html"
                            data-bs-toggle="tooltip"
                            title="Edit Contact"
                          >
                            <i className="bi bi-pencil-fill" />
                          </a>
                        </div>
                        <div>
                          <a href="#" className="me-2">
                            @Amitabh
                          </a>
                          <a href="#" className="me-2">
                            <i className="bi bi-telephone-fill" /> +91 987-654-3210
                          </a>
                          <a href="#" className="me-2">
                            <i className="bi bi-envelope-fill" /> amit@bachchan.com
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="row g-0 pt-3 workroomUserInfoExpand">
                  <div className="col border-top">
                    <div className="CD_Info_Row border-bottom p-3">
                      <div className="row align-items-center">
                        <div className="col-sm-3 col text-left text-sm-end mb-2 mb-sm-0">Primary</div>
                        <div className="col-sm-2 col text-end text-sm-center mb-2 mb-sm-0">
                          <span data-bs-toggle="tooltip" data-placement="top" title="Public Information">
                            <i className="bi bi-envelope-fill" />
                          </span>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                          <a
                            href="javascript:"
                            className="email_deliverable btnOpenChatDockEmail"
                            data-type="Email"
                            data-value="amitabh@domain.com"
                            data-bs-toggle="tooltip"
                            title="amitabh@domain.com - deliverable"
                          >
                            amitabh@domain.com <i className="bi bi-check2" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="CD_Info_Row border-bottom p-3">
                      <div className="row align-items-center">
                        <div className="col-sm-3 col text-left text-sm-end mb-2 mb-sm-0">Main</div>
                        <div className="col-sm-2 col text-end text-sm-center mb-2 mb-sm-0">
                          <span data-bs-toggle="tooltip" data-placement="top" title="Private Information">
                            <i className="bi bi-phone-fill" />
                          </span>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                          <a href="#" className=" btnOpenChatDock">
                            987-654-3210
                          </a>
                          <div className="showOnHover d-inline-block">
                            <a
                              href="javascript:"
                              className="btn btn-secondary btnQuickAction btnOpenChatDock btnOpenChatDockSMS"
                              data-type="SMS"
                              data-value="987-654-3210"
                              data-bs-toggle="tooltip"
                              data-placement="top"
                              title="SMS"
                            >
                              <i className="bi bi-phone-fill" />
                            </a>
                            <a
                              href="javascript:"
                              className="btn btn-secondary btnQuickAction btnOpenChatDock btnOpenChatDockCall"
                              data-type="Phone"
                              data-value="987-654-3210"
                              data-bs-toggle="tooltip"
                              data-placement="top"
                              title="Call"
                            >
                              <i className="bi bi-telephone-fill" />
                            </a>
                          </div>
                        </div>
                        <div className="col-sm-1 col-xs-12 position-relative showOnHover CD_Action">
                          <a
                            href="javascript:"
                            className="btnCDEdit"
                            data-bs-toggle="tooltip"
                            data-placement="top"
                            title="Edit"
                          >
                            <i className="bi bi-pencil-fill" />
                          </a>
                          <a
                            href="javascript:"
                            className="btnCDDelete"
                            data-bs-toggle="tooltip"
                            data-placement="top"
                            title="Delete"
                          >
                            <i className="bi bi-trash-fill" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="CD_Info_Row border-bottom p-3">
                      <div className="row align-items-center">
                        <div className="col-sm-3 col text-left text-sm-end mb-2 mb-sm-0">Business</div>
                        <div className="col-sm-2 col text-end text-sm-center mb-2 mb-sm-0">
                          <span data-bs-toggle="tooltip" data-placement="top" title="Private Information">
                            <i className="bi bi-telephone-fill" />
                          </span>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                          <a href="#" className="btnOpenChatDock">
                            9876543210
                          </a>
                          <div className="showOnHover d-inline-block">
                            <a
                              href="javascript:"
                              className="btn btn-secondary btnQuickAction btnOpenChatDock btnOpenChatDockCall"
                              data-type="Phone"
                              data-value="9876543210"
                              data-bs-toggle="tooltip"
                              data-placement="top"
                              title="Call"
                            >
                              <i className="bi bi-telephone-fill" />
                            </a>
                          </div>
                        </div>
                        <div className="col-sm-1 col-xs-12 position-relative showOnHover CD_Action">
                          <a
                            href="javascript:"
                            className="btnCDEdit"
                            data-bs-toggle="tooltip"
                            data-placement="top"
                            title="Edit"
                          >
                            <i className="bi bi-pencil-fill" />
                          </a>
                          <a
                            href="javascript:"
                            className="btnCDDelete"
                            data-bs-toggle="tooltip"
                            data-placement="top"
                            title="Delete"
                          >
                            <i className="bi bi-trash-fill" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="CD_Info_Row border-bottom p-3">
                      <div className="row align-items-center">
                        <div className="col-sm-3 col text-left text-sm-end mb-2 mb-sm-0">Website (Work)</div>
                        <div className="col-sm-2 col text-end text-sm-center mb-2 mb-sm-0">
                          <span data-bs-toggle="tooltip" data-placement="top" title="Public Information">
                            <i className="bi bi-link-45deg" />
                          </span>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                          <a href="https://swiftcrm.com" target="_blank">
                            https://swiftcrm.com
                          </a>
                          <div className="showOnHover d-inline-block">
                            <a
                              href="https://swiftcrm.com"
                              target="_blank"
                              className="btn btn-secondary btnQuickAction"
                              data-bs-toggle="tooltip"
                              data-placement="top"
                              title="Visit"
                            >
                              <i className="bi bi-box-arrow-up-right" />
                            </a>
                          </div>
                        </div>
                        <div className="col-sm-1"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </React.Fragment>
  )
}

export default WorkroomHeader
