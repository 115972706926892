import React from "react"
import { Accordion } from "react-bootstrap"

const FilesPanel = () => {
  return (
    <div className="col-lg-12 mb-3" id="files_panel">
      <Accordion defaultActiveKey="0" className="accordion-item panel panel-beige" alwaysOpen>
        <Accordion.Header>
          <i className="bi bi-paperclip me-2" /> Files
        </Accordion.Header>
        <Accordion.Body>
          <div className="row">
            <div className="col-lg-12">
              <div className="float-end">
                <ul className="nav nav-tabs swiftCloudTabs d-none d-md-flex" role="tablist">
                  <li className="nav-item">
                    <button type="button" className="btn btn-secondary btnUpload">
                      <i className="bi bi-cloud-arrow-up-fill"></i>{" "}
                      <span className="d-none d-md-inline">Upload</span>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active"
                      id="drive-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#drive"
                      role="tab"
                      aria-controls="drive"
                      aria-selected="false"
                    >
                      <span data-bs-toggle="tooltip" data-bs-title="Drive">
                        <i className="bi bi-database-fill"></i>
                      </span>
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="small-icon-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#small-icon"
                      role="tab"
                      aria-controls="small-icon"
                      aria-selected="false"
                    >
                      <span data-bs-toggle="tooltip" data-bs-title="Small Icons">
                        <i className="bi bi-ui-checks-grid"></i>
                      </span>
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="large-icon-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#large-icon"
                      role="tab"
                      aria-controls="large-icon"
                      aria-selected="false"
                    >
                      <span data-bs-toggle="tooltip" data-bs-title="Large Icons">
                        <i className="bi bi-stop-fill"></i>
                      </span>
                    </a>
                  </li>
                  <li className="nav-item nav-item-trash" role="presentation">
                    <a
                      className="nav-link"
                      href="#"
                      data-bs-toggle="tooltip"
                      data-placement="top"
                      title="View Templates"
                    >
                      <i className="bi bi-journals"></i>
                    </a>
                  </li>
                  <li className="nav-item nav-item-trash" role="presentation">
                    <a
                      className="nav-link"
                      href="#"
                      data-bs-toggle="tooltip"
                      data-placement="top"
                      title="Trash"
                    >
                      <i className="bi bi-trash-fill"></i>
                    </a>
                  </li>
                </ul>

                {/*mobile view*/}
                <ul className="nav nav-tabs swiftCloudTabs mobileTabs d-md-none d-flex mb-1" role="tablist">
                  <li className="nav-item">
                    <button type="button" className="btn btn-secondary btnUpload me-1">
                      <i className="bi bi-cloud-arrow-up-fill"></i>
                    </button>
                  </li>
                  <li className="nav-item">
                    <div className="btn-group btnDriveView" role="group">
                      <button
                        id="btnGroupDrop3"
                        type="button"
                        className="btn btn-default dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="bi bi-database-fill"></i>
                      </button>
                      <ul className="dropdown-menu" aria-labelledby="btnGroupDrop3">
                        <li>
                          <a
                            className="dropdown-item"
                            id="drive-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#drive"
                            role="tab"
                            aria-controls="drive"
                            aria-selected="false"
                          >
                            <i className="bi bi-database-fill"></i> Drive
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            id="small-icon-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#small-icon"
                            role="tab"
                            aria-controls="small-icon"
                            aria-selected="false"
                          >
                            <i className="bi bi-ui-checks-grid"></i> Small Icons
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            id="large-icon-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#large-icon"
                            role="tab"
                            aria-controls="large-icon"
                            aria-selected="false"
                          >
                            <i className="bi bi-stop-fill"></i> Large Icons
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <i className="bi bi-journals"></i> Templates
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <i className="bi bi-trash-fill"></i> Trash
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="tab-content autoAdjustSearchBox" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="drive"
                  role="tabpanel"
                  aria-labelledby="drive-tab"
                >
                  <table id="tblDrive" className="table dataTable" cellSpacing="0" width="100%">
                    <thead>
                      <tr>
                        <th width="5%">&nbsp;</th>
                        <th width="70%">
                          Name/Label{" "}
                          <button
                            type="button"
                            className="btn btn-sm btnTHSoryByTags float-end me-3"
                            onClick="event.stopPropagation();"
                            data-bs-toggle="modal"
                            data-bs-target="#ContactsSortByTagModal"
                            title="Advanced Tag Search"
                          >
                            <span data-bs-toggle="tooltip" title="Advanced Tag Search">
                              <i className="bi bi-tag-fill"></i>
                            </span>
                          </button>
                        </th>
                        <th width="25%" className="d-none d-md-table-cell">
                          Last Activity
                        </th>
                      </tr>
                      <tr className="d-none table_action_container">
                        <td colSpan="3" className="text-left">
                          <div className="batch-action-container">
                            <button className="btn btn-trash my-1">
                              <i className="bi bi-trash-fill"></i> Move to Trash
                            </button>
                            <button
                              className="btn btn-default my-1"
                              data-bs-toggle="tooltip"
                              data-placement="top"
                              title="Archive (Searchable but Hidden)"
                            >
                              <i className="bi bi-file-earmark-zip-fill"></i> Archive
                            </button>
                            <button className="btn btn-secondary my-1">
                              <i className="bi bi-tag-fills"></i> Apply Tag(s)
                            </button>
                            <button className="btn btn-success my-1">
                              <i className="bi bi-person-plus-fill"></i> Manage Sharing
                            </button>
                            <button className="btn btn-primary my-1">
                              <i className="bi bi-unlock-fill"></i> Manage Permissions
                            </button>
                            <button className="btn btn-secondary my-1">
                              <i className="bi bi-arrows-move"></i> Move To...
                            </button>
                          </div>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center tableColChkDel">
                          <div className="tableColChkDelBox">
                            <input type="checkbox" name="fileid[]" id="C_ID17" className="css-checkbox" />
                            <label htmlFor="C_ID17" className="css-checkbox-label"></label>
                            <span className="tableColChkSpan pdfIconBg">
                              <i className="bi bi-file-earmark-pdf-fill"></i>
                            </span>
                          </div>
                        </td>
                        <td>
                          <a href="file_pdf.html">XYZ.pdf</a>
                          <div className="SwiftCloudSharedWithUsers">
                            <ul>
                              <li>
                                <a
                                  href="SOCIALIZERPOPUP_SHOWCURRENTSTATUS"
                                  data-bs-toggle="tooltip"
                                  title="SwiftCloud"
                                >
                                  <img src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/roger.jpg`} />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="SOCIALIZERPOPUP_SHOWCURRENTSTATUS"
                                  data-bs-toggle="tooltip"
                                  title="Amitabh Bachchan"
                                >
                                  <img
                                    src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/bachchan-amitabh-image.jpg`}
                                  />
                                </a>
                              </li>
                            </ul>
                          </div>
                          <a
                            href="LINK_TO_SOCIALIZER_POPUP"
                            className="btnSocializer"
                            data-bs-toggle="tooltip"
                            title="Share / Attach to Contact"
                          >
                            <i className="bi bi-person-plus-fill"></i>
                          </a>
                        </td>
                        <td className="d-none d-lg-table-cell">
                          <div className="lastActivityBox">
                            <time className="timeago" dateTime="2022-05-01"></time>
                          </div>
                          <div className="statusActionBox">
                            <a
                              href="javascript:;"
                              className="btn btn-edit btnDownload"
                              data-bs-toggle="tooltip"
                              title="Download"
                            >
                              <i className="bi bi-cloud-arrow-down-fill"></i>
                            </a>
                            <a
                              href="javascript:;"
                              className="btn btn-delete"
                              data-bs-toggle="tooltip"
                              title="Delete"
                            >
                              <i className="bi bi-trash-fill"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center tableColChkDel">
                          <div className="tableColChkDelBox">
                            <input type="checkbox" name="fileid[]" id="C_ID4" className="css-checkbox" />
                            <label htmlFor="C_ID4" className="css-checkbox-label"></label>
                            <span className="tableColChkSpan btn-secondary">
                              <i className="bi bi-table"></i>
                            </span>
                          </div>
                        </td>
                        <td>
                          <a href="#">Spreadsheet</a>
                          <a
                            href="LINK_TO_SOCIALIZER_POPUP"
                            className="btnSocializer"
                            data-bs-toggle="tooltip"
                            title="Share / Attach to Contact"
                          >
                            <i className="bi bi-person-plus-fill"></i>
                          </a>
                        </td>
                        <td className="d-none d-lg-table-cell">
                          <div className="lastActivityBox">
                            <time className="timeago" dateTime="2022-05-01"></time>
                          </div>
                          <div className="statusActionBox">
                            <a
                              href="javascript:;"
                              className="btn btn-delete"
                              data-bs-toggle="tooltip"
                              title="Delete"
                            >
                              <i className="bi bi-trash-fill"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  className="tab-pane fade"
                  id="small-icon"
                  role="tabpanel"
                  aria-labelledby="small-icon-tab"
                >
                  <ul className="smallIconList">
                    <li>
                      <a href="#">
                        <span className="btn-secondary fileTypeIcon pdfIconBg">
                          <i className="bi bi-file-earmark-pdf-fill"></i>
                        </span>
                        <span className="fileName">XYZ.pdf</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span className="btn-secondary fileTypeIcon">
                          <i className="bi bi-table"></i>
                        </span>
                        <span className="fileName">Spreadsheet</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  className="tab-pane fade"
                  id="large-icon"
                  role="tabpanel"
                  aria-labelledby="large-icon-tab"
                >
                  <ul className="largeIconList">
                    <li className="btn-secondary">
                      <a href="#" className="pdfIconBg">
                        <span className="fileTypeIcon ">
                          <i className="bi bi-file-earmark-pdf-fill"></i>
                        </span>
                        <span className="fileName">XYZ.pdf</span>
                      </a>
                    </li>
                    <li className="btn-secondary">
                      <a href="#">
                        <span className="fileTypeIcon">
                          <i className="bi bi-table"></i>
                        </span>
                        <span className="fileName">Spreadsheet</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Accordion.Body>
      </Accordion>
    </div>
  )
}

export default FilesPanel
