import * as React from "react"
import PrivateRoute from "../../../components/privateRoute"
import Wrokroomdetail from "../../../components/Drive/Workroom/workroomDetail"
import ReduxWrapper from "../../../redux/reduxWrapper"

const Workroom = props => {
  return <PrivateRoute component={Wrokroomdetail} location={props.location} selectedDocId={props.id} />
}

const WrappedPage = props => <ReduxWrapper element={<Workroom {...props} />} />
export default WrappedPage
