import React from "react"

const WorkroomChatPanel = () => {
  return (
    <div className="col-lg-6">
      <div className="SCChatDockNonSocialAssetOnly SCChatDockForWorkroom">
        <div className="ChatDockHeader">
          <div className="user_info">
            <div className="dropdown">
              <button
                className="btn btn-default dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/bachchan-amitabh-image.jpg`}
                  className="img-fluid"
                  alt="Amitabh Bachchan"
                />
                <span>Workroom</span>
              </button>
              <ul className="dropdown-menu">
                <li>
                  <a
                    className="dropdown-item SCChatDockForWorkroomSelectOpt"
                    data-type="Workroom"
                    href="javascript:"
                  >
                    <i className="bi bi-people-fill" /> Workroom
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="javascript:" data-type="User">
                    <img src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/swiftcloud-support.png`} />
                    <span>SwiftCloud Support</span>
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="javascript:" data-type="User">
                    <img src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/roger.jpg`} />
                    <span>Roger V.</span>
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="javascript:" data-type="User">
                    <img src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/tejas.png`} />
                    <span>Tejas H.</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="ChatDockInputOptContainer">
            <div className="dropdown ChatDockInputOpt">
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="bi bi-sticky-fill" /> Add Note
              </button>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" data-type="history" href="javascript:">
                    <i className="bi bi-arrow-counterclockwise" /> History
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-type="Private_note" href="javascript:">
                    <i className="bi bi-sticky-fill" /> Add Note
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-type="Sequence" href="javascript:">
                    <i className="bi bi-three-dots" /> Sequence(s) Add/Remove
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    data-type="ChatTo"
                    data-value="Amitabh Bachchan"
                    href="javascript:"
                  >
                    <i className="bi bi-chat-dots-fill" /> Chat to Amitabh Bachchan
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    data-type="Email"
                    data-value="amitabh@domain.com"
                    href="javascript:"
                  >
                    <i className="bi bi-envelope-fill" /> Email to amitabh@domain.com
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    data-type="Email"
                    data-value="amitabh2@domain.com"
                    href="javascript:"
                  >
                    <i className="bi bi-envelope-fill" /> Email to amitabh2@domain.com
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-type="SMS" data-value="987-654-3210" href="javascript:">
                    <i className="bi bi-phone-fill" /> SMS to 987-654-3210
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-type="Phone" data-value="123-456-7890" href="javascript:">
                    <i className="bi bi-telephone-fill" /> Call 123-456-7890
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-type="VideoRecording" href="javascript:">
                    <i className="bi bi-camera-video-fill" /> Record Video
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-type="Videocall" href="javascript:">
                    <i className="bi bi-camera-video-fill" /> Videocall
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    data-type="Map"
                    data-value="2800 Breezewood Avenue"
                    href="javascript:"
                  >
                    <i className="bi bi-geo-alt-fill" /> Directions to 2800 Breezewood Avenue
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    data-type="Map"
                    data-value="10945 Hortense Street"
                    href="javascript:"
                  >
                    <i className="bi bi-geo-alt-fill" /> Directions to 10945 Hortense Street
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    data-type="PostalMail"
                    data-value="2800 Breezewood Avenue"
                    href="javascript:"
                  >
                    <i className="bi bi-box-seam-fill" /> Mail to 2800 Breezewood Avenue
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    data-type="PostalMail"
                    data-value="10945 Hortense Street"
                    href="javascript:"
                  >
                    <i className="bi bi-box-seam-fill" /> Mail to 10945 Hortense Street
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <a href="javascript:" className="btnCloseChatDock" data-bs-toggle="tooltip" title="Close">
            <i className="bi bi-x-lg" />
          </a>
        </div>

        <div className="ChatDockHistoryContainer">
          <span className="dummyChatHistory">
            Load history for <i className="bi bi-people-fill" /> Workroom
          </span>
        </div>

        <div className="ChatDockFooterContainer">
          {/*PHONE CALL*/}
          <div className="ChatDockOpt ChatDockPhoneOpt d-none">
            <div className="phoneCallingContainer">
              <div className="row align-items-center mb-2 phone-started d-none">
                <div className="col-sm-6">
                  <button type="button" className="btn btn-danger btn-lg btnChatDockCallEnd">
                    <i className="bi bi-x-lg" /> Hang Up
                  </button>
                </div>
                <div className="col-sm-6 text-end">
                  <div className="d-inline-block">
                    <strong className="timerStart fs-4">00:00:00</strong>
                  </div>

                  <div className="text-end d-inline-block phone-started ms-2 d-none">
                    <span
                      className="btn btn-default chatDockPhoneCallBtns ChatDockPhoneCallRecord"
                      data-bs-toggle="tooltip"
                      data-bs-title="Record"
                    >
                      <input type="checkbox" id="ChatDockPhoneCallRecord" />
                      <label htmlFor="ChatDockPhoneCallRecord" className="checkmark"></label>
                    </span>
                    <span
                      className="btn btn-default chatDockPhoneCallBtns ChatDockPhoneCallMute"
                      data-bs-toggle="tooltip"
                      data-bs-title="Mute"
                    >
                      <input type="checkbox" id="ChatDockPhoneCallMute" />
                      <label htmlFor="ChatDockPhoneCallMute" className="checkmark"></label>
                    </span>
                    <span
                      className="btn btn-default chatDockPhoneCallBtns ChatDockPhoneCallHold"
                      data-bs-toggle="tooltip"
                      data-bs-title="Hold"
                    >
                      <input type="checkbox" id="ChatDockPhoneCallHold" />
                      <label htmlFor="ChatDockPhoneCallHold" className="checkmark"></label>
                    </span>
                    <button type="button" className="btn btnChatDockPhoneCallAudioSettings">
                      <i
                        className="bi bi-gear-fill"
                        data-bs-toggle="tooltip"
                        data-bs-title="Audio Settings"
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-sm-5">
                  <div className="mb-2">
                    <textarea
                      className="form-control ChatDockPhoneCallNote"
                      rows="6"
                      placeholder="Optional Call Notes"
                    ></textarea>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="text-start ChatDockPrivateNoteTools">
                      <div className="dropdown d-inline-block btnGroupChatDockVideoMail">
                        <button
                          className="dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i
                            data-bs-toggle="tooltip"
                            title="Video Mail"
                            className="bi bi-camera-video-fill"
                          />
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <a className="dropdown-item" href="#chatDockVideoMail" data-bs-toggle="modal">
                              <i className="bi bi-play-fill" /> Select Existing / Template
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item btnOpenChatDockVideoCall" href="javascript:">
                              <i className="bi bi-record-fill" /> Record New
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item btnChatDockVideoMailUpload" href="javascript:">
                              <i className="bi bi-cloud-arrow-up-fill" /> Upload
                            </a>
                          </li>
                        </ul>
                      </div>
                      <a href="#chatDockURLInjector" data-bs-toggle="modal">
                        <i data-bs-toggle="tooltip" title="Add URL" className="bi bi-link-45deg" />
                      </a>
                      <a href="#Global_Uploader" data-bs-toggle="modal">
                        <i data-bs-toggle="tooltip" title="Insert Image" className="bi bi-camera-fill" />
                      </a>
                      <a
                        href="javascript:"
                        className="chatNoteImg"
                        data-bs-toggle="tooltip"
                        data-bs-title="Upload file"
                      >
                        <i className="bi bi-paperclip" />
                      </a>
                      <a
                        href="javascript:"
                        id="ChatDockPhoneCallEmoji"
                        data-bs-toggle="tooltip"
                        data-bs-title="Inject Emoji"
                      >
                        <i className="bi bi-emoji-smile" />
                      </a>
                      <input
                        type="file"
                        accept="image/*"
                        className="chatNoteImgFile"
                        style={{
                          visibility: "hidden",
                          position: "absolute",
                        }}
                      />
                    </div>
                    <div className="text-end">
                      <button type="button" className="btn btn-primary btnChatDockSendPrivateNote">
                        <i className="bi bi-check2" /> Add Note
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-sm-7 text-center phone-ended">
                  <button className="btn btn-primary btnChatDockCallNow mt-2 mt-sm-0">
                    <i className="bi bi-telephone-fill" /> Call Now
                  </button>
                </div>
                <div className="col-sm-7 text-center phone-started">
                  <div className="row g-0 align-items-center">
                    <div className="col-sm-6 text-center phone-started pb-3 d-none chatDockTransferMergeBox">
                      <div className="ChatDockPhoneCallHoldOptContainer phone-started d-none">
                        {/*CALL NOW - TRANSFER CALL*/}
                        <div className="ChatDockPhoneCallTransferCallContainer text-start mt-2 d-none">
                          <p className="fw-bold mb-1 position-relative">
                            <i className="bi bi-reply-fill flip-horizontal" /> Transfer Call
                            <a href="javascript:" className="btnChatDockCancelTransferCall">
                              <i className="bi bi-x-lg" />
                            </a>
                          </p>

                          <div className="mb-1">
                            <input
                              type="radio"
                              name="chatDockForwardCallTo"
                              id="chatDockForwardCallToContact"
                              value="Contact"
                              checked="checked"
                              className="css-checkbox"
                            />
                            <label htmlFor="chatDockForwardCallToContact" className="css-radio-label me-2">
                              Contact
                            </label>
                            <input
                              type="radio"
                              name="chatDockForwardCallTo"
                              id="chatDockForwardCallToPhone"
                              value="Phone"
                              className="css-checkbox"
                            />
                            <label htmlFor="chatDockForwardCallToPhone" className="css-radio-label me-2">
                              Phone
                            </label>
                          </div>
                          <div className="ChatDockPhoneCallTransferToInputContainer mb-2">
                            <input
                              type="text"
                              className="form-control chatDockForwardCallToContactInput"
                              placeholder="Name or Phone Number"
                            />
                            <input
                              type="tel"
                              className="form-control chatDockForwardCallToPhoneInput d-none"
                              placeholder=""
                            />
                          </div>
                          <div className="ChatDockPhoneCallTransferToContainer mb-2 d-none">
                            Also on call
                            <ul className="user-card-list">
                              <li>
                                <a href="javascript:" className="btnChatDockRemovePhoneCallMerge">
                                  <i className="bi bi-x-lg" />
                                </a>
                                <img
                                  src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/Rosa.jpg`}
                                  alt=""
                                  className="user_avatar"
                                />
                                Rosa
                              </li>
                            </ul>
                          </div>
                          <div>
                            <button
                              type="button"
                              className="btn btn-primary w-100 btnChatDockTransferCallDialNo"
                            >
                              <i className="bi bi-telephone-fill" /> Dial 3rd Number
                            </button>
                            {/*SHOW AFTER PHONE PICKED UP / CALL IS LIVE*/}
                            <button
                              type="button"
                              className="btn btn-danger w-100 d-none mb-2 btnChatDockTransferCallHangUp"
                            >
                              <i className="bi bi-x-lg" /> Hang Up
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary w-100 d-none btnChatDockTransferCall"
                            >
                              <i className="bi bi-reply-fill flip-horizontal" /> Transfer
                            </button>
                          </div>
                        </div>

                        {/*CALL NOW - MERGE CALL*/}
                        <div className="ChatDockPhoneCallMergeCallContainer text-start mt-2 d-none">
                          <p className="fw-bold mb-1 position-relative">
                            <i className="bi bi-share-fill flip-horizontal" /> Add to Call (3-Way)
                            <a href="javascript:" className="btnChatDockCancelMergeCall">
                              <i className="bi bi-x-lg" />
                            </a>
                          </p>

                          <div className="mb-1">
                            <input
                              type="radio"
                              name="chatDockMergeCallTo"
                              id="chatDockMergeCallToContact"
                              value="Contact"
                              checked="checked"
                              className="css-checkbox"
                            />
                            <label htmlFor="chatDockMergeCallToContact" className="css-radio-label me-2">
                              Contact
                            </label>
                            <input
                              type="radio"
                              name="chatDockMergeCallTo"
                              id="chatDockMergeCallToPhone"
                              value="Phone"
                              className="css-checkbox"
                            />
                            <label htmlFor="chatDockMergeCallToPhone" className="css-radio-label me-2">
                              Phone
                            </label>
                          </div>
                          <div className="ChatDockPhoneCallMergeWithInputContainer mb-2">
                            <input
                              type="text"
                              className="form-control chatDockMergeCallToContactInput"
                              placeholder="Name or Phone Number"
                            />
                            <input
                              type="tel"
                              className="form-control chatDockMergeCallToPhoneInput d-none"
                              placeholder=""
                            />
                          </div>
                          <div className="ChatDockPhoneCallMergeWithContainer mb-2 d-none">
                            Also on call
                            <ul className="user-card-list">
                              <li>
                                <a href="javascript:" className="btnChatDockRemovePhoneCallMerge">
                                  <i className="bi bi-x-lg" />
                                </a>
                                <img
                                  src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/Rosa.jpg`}
                                  alt=""
                                  className="user_avatar"
                                />
                                Rosa
                              </li>
                            </ul>
                          </div>
                          <div>
                            <button
                              type="button"
                              className="btn btn-primary w-100 btnChatDockMergeCallDialNo"
                            >
                              <i className="bi bi-telephone-fill" /> Dial 3rd Number
                            </button>
                            {/*SHOW AFTER PHONE PICKED UP / CALL IS LIVE*/}
                            <button
                              type="button"
                              className="btn btn-danger w-100 d-none mb-2 btnChatDockMergeCallHangUp"
                            >
                              <i className="bi bi-x-lg" /> Hang Up
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary w-100 d-none btnChatDockMergeCall"
                            >
                              <i className="bi bi-share-fill flip-horizontal" /> Merge
                            </button>
                          </div>
                        </div>

                        <div className="ChatDockPhoneCallHoldOpt mt-3 d-none">
                          <input
                            type="radio"
                            name="ChatDockPhoneCallHoldOpt"
                            id="ChatDockPhoneCallTransfer"
                            value="Transfer"
                          />
                          <label htmlFor="ChatDockPhoneCallTransfer" className="checkmark">
                            Transfer
                          </label>
                          <input
                            type="radio"
                            name="ChatDockPhoneCallHoldOpt"
                            id="ChatDockPhoneCallMerge"
                            value="Merge"
                          />
                          <label htmlFor="ChatDockPhoneCallMerge" className="checkmark">
                            Merge
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 text-center phone-started py-3 d-none">
                      <div className="keypad_container">
                        <button className="btn btn-default keypad__key" value="1">
                          1
                        </button>
                        <button className="btn btn-default keypad__key" value="2">
                          2
                        </button>
                        <button className="btn btn-default keypad__key" value="3">
                          3
                        </button>
                        <button className="btn btn-default keypad__key" value="4">
                          4
                        </button>
                        <button className="btn btn-default keypad__key" value="5">
                          5
                        </button>
                        <button className="btn btn-default keypad__key" value="6">
                          6
                        </button>
                        <button className="btn btn-default keypad__key" value="7">
                          7
                        </button>
                        <button className="btn btn-default keypad__key" value="8">
                          8
                        </button>
                        <button className="btn btn-default keypad__key" value="9">
                          9
                        </button>
                        <button className="btn btn-default keypad__key" value="*">
                          *
                        </button>
                        <button className="btn btn-default keypad__key" value="0">
                          0
                        </button>
                        <button className="btn btn-default keypad__key" value="#">
                          #
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*PRIVATE NOTE*/}
          <div className="ChatDockOpt ChatDockPrivateNoteOpt">
            <div className="mb-2">
              <textarea className="form-control" placeholder="Add Note" rows="3"></textarea>
            </div>
            <div className="row">
              <div className="col-sm-8 text-start ChatDockPrivateNoteTools">
                <div className="dropdown d-inline-block btnGroupChatDockVideoMail">
                  <button
                    className="dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i data-bs-toggle="tooltip" title="Video Mail" className="bi bi-camera-video-fill" />
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="#chatDockVideoMail" data-bs-toggle="modal">
                        <i className="bi bi-play-fill" /> Select Existing / Template
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item btnChatDockVideoRecording" href="javascript:">
                        <i className="bi bi-record-fill" /> Record New
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item btnChatDockVideoMailUpload" href="javascript:">
                        <i className="bi bi-cloud-arrow-up-fill" /> Upload
                      </a>
                    </li>
                  </ul>
                </div>
                <a href="#chatDockURLInjector" data-bs-toggle="modal">
                  <i data-bs-toggle="tooltip" title="Add URL" className="bi bi-link-45deg" />
                </a>
                <a href="#Global_Uploader" data-bs-toggle="modal">
                  <i data-bs-toggle="tooltip" title="Insert Image" className="bi bi-camera-fill" />
                </a>
                <a
                  href="javascript:"
                  className="chatNoteImg"
                  data-bs-toggle="tooltip"
                  data-bs-title="Upload file"
                >
                  <i className="bi bi-paperclip" />
                </a>
                <a
                  href="javascript:"
                  id="ChatDockPrivateNoteEmoji"
                  data-bs-toggle="tooltip"
                  data-bs-title="Inject Emoji"
                >
                  <i className="bi bi-emoji-smile" />
                </a>
                <input
                  type="file"
                  accept="image/*"
                  className="chatNoteImgFile"
                  style={{ visibility: "hidden", position: "absolute" }}
                />
              </div>
              <div className="col-sm-4 text-end">
                <button type="button" className="btn btn-primary btnChatDockSendPrivateNote">
                  <i className="bi bi-check2" /> Add Note
                </button>
              </div>
            </div>
          </div>

          {/*SEQUENCE*/}
          <div className="ChatDockOpt ChatDockSequenceOpt d-none">
            <div className="ChatDockUserSequenceList">
              <ul>
                <li>
                  <i className="bi bi-three-dots" /> Amitabh B. added to{" "}
                  <a href="#" className="dashedLinked">
                    Appointment Confirmation
                  </a>{" "}
                  on Jan 31, 2023 to 11:15 am{" "}
                  <a
                    href="javascript:"
                    className="btnChatDockRemoveFromSequence"
                    data-bs-toggle="tooltip"
                    title="Remove from Sequence"
                  >
                    <i className="bi bi-x-lg" />
                  </a>
                </li>
                <li>
                  <i className="bi bi-three-dots" /> Amitabh B. added to{" "}
                  <a href="#" className="dashedLinked">
                    How'd we do?
                  </a>{" "}
                  on Feb 3, 2023 at 4:00 pm{" "}
                  <a
                    href="javascript:"
                    className="btnChatDockRemoveFromSequence"
                    data-bs-toggle="tooltip"
                    title="Remove from Sequence"
                  >
                    <i className="bi bi-x-lg" />
                  </a>
                </li>
                <li>
                  <i className="bi bi-three-dots" /> Amitabh B. added to{" "}
                  <a href="#" className="dashedLinked">
                    Appointment Missed
                  </a>{" "}
                  on Feb 1, 2023 at 11:15 am{" "}
                  <a
                    href="javascript:"
                    className="btnChatDockRemoveFromSequence"
                    data-bs-toggle="tooltip"
                    title="Remove from Sequence"
                  >
                    <i className="bi bi-x-lg" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="row row-cols-lg-auto g-2 mt-2 align-items-center justify-content-center">
              <div className="col-12 col-sm-4">
                <div className="dropdown chatDockAddSequenceContainer">
                  <button
                    className="btn btnAddNewGreenDashed dropdown-toggle btnChatDockAddSequence"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span data-bs-toggle="tooltip" data-bs-title="List of Sequences">
                      <i className="bi bi-plus-lg" />
                    </span>
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="javascript:" data-value="Appointment Confirmation">
                        Appointment Confirmation
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="javascript:" data-value="How'd we do?">
                        How'd we do?
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="javascript:" data-value="Appointment Missed">
                        Appointment Missed
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-sm-4 ChatDockSequenceDateTimeContainer d-none">
                <input
                  type="text"
                  name="ChatDockSequenceDateTime"
                  className="form-control ChatDockSequenceDateTimeInput"
                />
              </div>
              <div className="col-12 col-sm-4 ChatDockSequenceSaveBtnContainer d-none">
                <button className="btn btn-primary btbChatDockSaveSequence">
                  <i className="bi bi-check2" /> Save/Add
                </button>
              </div>
            </div>
          </div>

          {/*CHAT TO*/}
          <div className="ChatDockOpt ChatDockChatToOpt d-none">
            <div className="mb-2 ChatDockEmailToOpt w-100">
              <div className="row align-items-center">
                <div className="col-sm-12">
                  <div className="d-inline-block dropup">
                    <button
                      className="btn btnAddNewGreenDashed"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span data-bs-toggle="tooltip" title="AImo">
                        <i className="bi bi-robot" />
                      </span>
                    </button>
                    <ul className="dropdown-menu scrollable-menu">
                      <li>
                        <strong className="dropdown-item-text">== Email ==</strong>
                      </li>
                      <li>
                        <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                          AIDA - Attn, Interest, Desire, Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                          Pain, Agitate, Solve
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                          Cold Email
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                          Email Newsletter
                        </a>
                      </li>
                      <li>
                        <strong className="dropdown-item-text">== ARTICLES & BLOG ==</strong>
                      </li>
                      <li>
                        <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                          Article Writer Collab Style
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                          Article Writer Title Dependent
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                          Article Rewriter (English)
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                          Article Outliner
                        </a>
                      </li>
                      <li>
                        <strong className="dropdown-item-text">== SOCIAL MEDIA ==</strong>
                      </li>
                      <li>
                        <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                          Twitter Tweets
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                          LinkedIn Posts
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                          Instagram Captions
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                          YouTube Helper
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                          TikTok Helper
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="d-inline-block">
                    <button
                      className="btn btnAddNewGreenDashed"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span data-bs-toggle="tooltip" title="Templates">
                        <i className="bi bi-journals" />
                      </span>
                    </button>
                    <ul className="dropdown-menu scrollable-menu">
                      <li>
                        <a className="dropdown-item" href="javascript:">
                          Doc 1
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="javascript:">
                          Doc 2
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="d-inline-block">
                    <button
                      className="btn btnAddNewGreenDashed"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span data-bs-toggle="tooltip" title="Snippets">
                        <i className="bi bi-pencil-square" />
                      </span>
                    </button>
                    <ul className="dropdown-menu scrollable-menu">
                      <li>
                        <a className="dropdown-item" href="javascript:">
                          Snippets 1
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="javascript:">
                          Snippets 2
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-2">
              <textarea className="form-control ChatDockEmailBox" placeholder="Message" rows="5"></textarea>
            </div>
            <div className="mb-2 text-end">
              <div className="row">
                <div className="col-sm-8 text-start ChatDockPrivateNoteTools">
                  <div className="dropdown d-inline-block btnGroupChatDockVideoMail">
                    <button
                      className="dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i data-bs-toggle="tooltip" title="Video Mail" className="bi bi-camera-video-fill" />
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#chatDockVideoMail" data-bs-toggle="modal">
                          <i className="bi bi-play-fill" /> Select Existing / Template
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item btnChatDockVideoRecording" href="javascript:">
                          <i className="bi bi-record-fill" /> Record New
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item btnChatDockVideoMailUpload" href="javascript:">
                          <i className="bi bi-cloud-arrow-up-fill" /> Upload
                        </a>
                      </li>
                    </ul>
                  </div>
                  <a href="#chatDockURLInjector" data-bs-toggle="modal">
                    <i data-bs-toggle="tooltip" title="Add URL" className="bi bi-link-45deg" />
                  </a>
                  <a href="#Global_Uploader" data-bs-toggle="modal">
                    <i data-bs-toggle="tooltip" title="Insert Image" className="bi bi-camera-fill" />
                  </a>
                  <a href="javascript:" className="chatNoteImg" data-bs-toggle="tooltip" title="Upload file">
                    <i className="bi bi-paperclip" />
                  </a>
                  <a
                    href="javascript:"
                    id="ChatDockChatToEmoji"
                    data-bs-toggle="tooltip"
                    title="Inject Emoji"
                  >
                    <i className="bi bi-emoji-smile" />
                  </a>
                  <input
                    type="file"
                    accept="image/*"
                    className="chatNoteImgFile"
                    style={{ visibility: "hidden", position: "absolute" }}
                  />
                </div>
                <div className="col-sm-4 text-end">
                  <button type="button" className="btn btn-primary btnChatDockSendChatMsg">
                    <i className="bi bi-send-fill" /> Send
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/*EMAIL*/}
          <div className="ChatDockOpt ChatDockEmailOpt d-none">
            <div className="mb-2 ChatDockEmailToOpt w-100">
              <div className="row align-items-center">
                <div className="col-sm-4">
                  <div className="d-inline-block">
                    <button
                      className="btn btnAddNewGreenDashed"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span data-bs-toggle="tooltip" title="AImo">
                        <i className="bi bi-robot" />
                      </span>
                    </button>
                    <ul className="dropdown-menu scrollable-menu">
                      <li>
                        <strong className="dropdown-item-text">== Email ==</strong>
                      </li>
                      <li>
                        <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                          AIDA - Attn, Interest, Desire, Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                          Pain, Agitate, Solve
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                          Cold Email
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                          Email Newsletter
                        </a>
                      </li>
                      <li>
                        <strong className="dropdown-item-text">== ARTICLES & BLOG ==</strong>
                      </li>
                      <li>
                        <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                          Article Writer Collab Style
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                          Article Writer Title Dependent
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                          Article Rewriter (English)
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                          Article Outliner
                        </a>
                      </li>
                      <li>
                        <strong className="dropdown-item-text">== SOCIAL MEDIA ==</strong>
                      </li>
                      <li>
                        <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                          Twitter Tweets
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                          LinkedIn Posts
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                          Instagram Captions
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                          YouTube Helper
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                          TikTok Helper
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="d-inline-block">
                    <button
                      className="btn btnAddNewGreenDashed"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span data-bs-toggle="tooltip" title="Templates">
                        <i className="bi bi-journals" />
                      </span>
                    </button>
                    <ul className="dropdown-menu scrollable-menu chatDockEmailDocs">
                      <li>
                        <a className="dropdown-item" href="javascript:">
                          Doc 1
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="javascript:">
                          Doc 2
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="d-inline-block">
                    <button
                      className="btn btnAddNewGreenDashed"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span data-bs-toggle="tooltip" title="Snippets">
                        <i className="bi bi-pencil-square" />
                      </span>
                    </button>
                    <ul className="dropdown-menu scrollable-menu chatDockEmailSnippets">
                      <li>
                        <a className="dropdown-item" href="javascript:">
                          Snippets 1
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="javascript:">
                          Snippets 2
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="chatDockEmailViaCcBccControls">
                    <div className="chatDockEmailViaControls">
                      <div>via</div>
                      <select
                        name="ChatDockEmailUsing"
                        id="ChatDockEmailUsing"
                        className="form-select invisibleField"
                      >
                        <option>Roger@Yo.com</option>
                        <option>EmailsFromSettingsHere@domain.com</option>
                        <option>ImapFromSettings@domain.com</option>
                      </select>
                    </div>
                    <div className="chatDockEmailCcBccControls">
                      <div className="col-auto d-inline-block ChatDockEmailAs">
                        <div className="d-inline-block">
                          <button
                            className="btn btn-default"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span data-bs-toggle="tooltip" title="Default">
                              <i className="bi bi-envelope-fill" />
                            </span>
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              <a className="dropdown-item" href="javascript:">
                                <i className="bi bi-envelope-fill" /> Default
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="javascript:">
                                <i className="bi bi-lock-fill" /> Secure Message (Login Required)
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="javascript:">
                                <i className="bi bi-code-slash" /> Branded Newsletter
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="javascript:">
                                <i className="bi bi-list" /> Plain Text
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-auto d-inline-block ChatDockEmailCcBcc">
                        <div className="d-inline-block">
                          <button
                            className="btn btn-default"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span data-bs-toggle="tooltip" title="Add Cc/Bcc">
                              <i className="bi bi-plus-lg" />
                            </span>
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              <a className="dropdown-item" href="javascript:">
                                <i className="bi bi-envelope-fill" /> Add Cc
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="javascript:">
                                <i className="bi bi-envelope-fill" /> Add Bcc
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-auto ChatDockEmailCcBccInput position-relative d-none">
                        <a href="javascript:" className="btnRemoveChatDockEmailCcBccInput">
                          <i className="bi bi-x-lg" />
                        </a>
                        <input type="email" className="form-control" placeholder="Enter email address" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-2">
              <input type="text" className="form-control" placeholder="Subject" />
            </div>
            <div className="mb-2">
              <textarea className="form-control ChatDockEmailBox" placeholder="Message" rows="5"></textarea>
            </div>
            <div className="mb-2 text-end">
              <div className="row">
                <div className="col-sm-8 text-start ChatDockPrivateNoteTools">
                  <div className="dropdown d-inline-block btnGroupChatDockVideoMail">
                    <button
                      className="dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i data-bs-toggle="tooltip" title="Video Mail" className="bi bi-camera-video-fill" />
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#chatDockVideoMail" data-bs-toggle="modal">
                          <i className="bi bi-play-fill" /> Select Existing / Template
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item btnChatDockVideoRecording" href="javascript:">
                          <i className="bi bi-record-fill" /> Record New
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item btnChatDockVideoMailUpload" href="javascript:">
                          <i className="bi bi-cloud-arrow-up-fill" /> Upload
                        </a>
                      </li>
                    </ul>
                  </div>
                  <a href="#chatDockURLInjector" data-bs-toggle="modal">
                    <i data-bs-toggle="tooltip" title="Add URL" className="bi bi-link-45deg" />
                  </a>
                  <a href="#Global_Uploader" data-bs-toggle="modal">
                    <i data-bs-toggle="tooltip" title="Insert Image" className="bi bi-camera-fill" />
                  </a>
                  <a href="javascript:" className="chatNoteImg" data-bs-toggle="tooltip" title="Upload file">
                    <i className="bi bi-paperclip" />
                  </a>
                  <a href="javascript:" id="ChatDockEmailEmoji" data-bs-toggle="tooltip" title="Inject Emoji">
                    <i className="bi bi-emoji-smile" />
                  </a>
                  <input
                    type="file"
                    accept="image/*"
                    className="chatNoteImgFile"
                    style={{ visibility: "hidden", position: "absolute" }}
                  />
                </div>
                <div className="col-sm-4 text-end">
                  <button type="button" className="btn btn-primary btnChatDockSendEmail">
                    <i className="bi bi-send-fill" /> Send
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/*SMS*/}
          <div className="ChatDockOpt ChatDockSMSOpt d-none">
            <div className="mb-2">
              <textarea className="form-control" id="smsMessagebox" placeholder="Message" rows="3"></textarea>
            </div>
            <div className="row">
              <div className="col-sm-8 text-start ChatDockPrivateNoteTools">
                <div className="dropdown d-inline-block btnGroupChatDockVideoMail">
                  <button
                    className="dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i data-bs-toggle="tooltip" title="Video Mail" className="bi bi-camera-video-fill" />
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="#chatDockVideoMail" data-bs-toggle="modal">
                        <i className="bi bi-play-fill" /> Select Existing / Template
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item btnChatDockVideoRecording" href="javascript:">
                        <i className="bi bi-record-fill" /> Record New
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item btnChatDockVideoMailUpload" href="javascript:">
                        <i className="bi bi-cloud-arrow-up-fill" /> Upload
                      </a>
                    </li>
                  </ul>
                </div>
                <a href="#chatDockURLInjector" data-bs-toggle="modal">
                  <i data-bs-toggle="tooltip" title="Add URL" className="bi bi-link-45deg" />
                </a>
                <a href="#Global_Uploader" data-bs-toggle="modal">
                  <i data-bs-toggle="tooltip" title="Insert Image" className="bi bi-camera-fill" />
                </a>
                <a href="javascript:" className="chatNoteImg" data-bs-toggle="tooltip" title="Upload file">
                  <i className="bi bi-paperclip" />
                </a>
                <a href="javascript:" id="ChatDockSMSEmoji" data-bs-toggle="tooltip" title="Inject Emoji">
                  <i className="bi bi-emoji-smile" />
                </a>
                <input
                  type="file"
                  accept="image/*"
                  className="chatNoteImgFile"
                  style={{ visibility: "hidden", position: "absolute" }}
                />
                <span className="ChatDockSMSCounter">140 remaining</span>
              </div>
              <div className="col-sm-4 text-end">
                <button type="button" className="btn btn-primary btnChatDockSendSMS">
                  <i className="bi bi-send-fill" /> Send
                </button>
              </div>
            </div>
          </div>

          {/*VIDEO RECORDING*/}
          <div className="ChatDockOpt ChatDockVideoRecordingOpt d-none">
            <div className="row align-items-center mb-2 ChatDockVideoRecordingActionContainer">
              <div className="col-lg-12">
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-danger btn-lg btnChatDockVideoRecordingEnd btnChatDockVideoRecordingAfterButton d-none"
                  >
                    <i className="bi bi-x-lg" /> Stop Recording
                  </button>

                  <div className="text-end">
                    <div className="d-inline-block btnChatDockVideoRecordingAfterButton d-none">
                      <strong className="videoRecordingTimer fs-4">00:00:00</strong>
                    </div>

                    <div className="text-end d-inline-block ms-2">
                      <button type="button" className="btn btnChatDockVideoAudioSettings">
                        <i
                          className="bi bi-gear-fill"
                          data-bs-toggle="tooltip"
                          data-bs-title="Video / Audio Settings"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ratio ratio-16x9">
              <div className="">
                <div className="chatDockStartVideoRecordingContainer">
                  <button type="button" className="btn btn-primary btn-lg btnChatDockStartVideoRecording">
                    <i className="bi bi-play-circle" /> Start Recording
                  </button>
                </div>
                <div className="ChatDockVideoRecordProcessing d-none text-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
                <div className="ChatDockVideoRecordingWebCamContainer d-none text-center">
                  Webcam goes here...
                </div>
                <div className="ChatDockVideoRecordingSaveContainer d-none text-center">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="input-group input-group-lg mb-3">
                        <span className="input-group-text">Save as</span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          value="Amitabh Bachchan Video Message {Date}"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <button className="btn btn-default btn-lg btnChatDockVideoRecordingDiscard">
                      <i className="bi bi-x-lg" /> Discard
                    </button>
                    {/*SAVE TO FILES + LOG TO HISTORY ABOVE*/}
                    <button className="btn btn-primary btn-lg">
                      <i className="bi bi-check2" /> Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*VIDEO CALL*/}
          <div className="ChatDockOpt ChatDockVideocallOpt d-none">
            <div className="row align-items-center mb-2 ChatDockVideocallActionContainer">
              <div className="col-lg-12">
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-danger btn-lg btnChatDockVideoCallEnd btnChatDockVideoCallAfterButton d-none"
                  >
                    <i className="bi bi-x-lg" /> End Meeting / Hang Up
                  </button>

                  <div className="text-end">
                    <div className="d-inline-block btnChatDockVideoCallAfterButton d-none">
                      <strong className="videoCallTimer fs-4">00:00:00</strong>
                    </div>

                    <div className="text-end d-inline-block ms-2">
                      <span
                        className="btn btn-default chatDockVideoCallBtns ChatDockVideoCallRecord btnChatDockVideoCallAfterButton d-none"
                        data-bs-toggle="tooltip"
                        data-bs-title="Record"
                      >
                        <input type="checkbox" id="ChatDockVideoCallRecord" />
                        <label htmlFor="ChatDockVideoCallRecord" className="checkmark"></label>
                      </span>
                      <span
                        className="btn btn-default chatDockVideoCallBtns ChatDockVideoCallMute btnChatDockVideoCallAfterButton d-none"
                        data-bs-toggle="tooltip"
                        data-bs-title="Mute"
                      >
                        <input type="checkbox" id="ChatDockVideoCallMute" />
                        <label htmlFor="ChatDockVideoCallMute" className="checkmark"></label>
                      </span>
                      <span
                        className="btn btn-default chatDockVideoCallBtns ChatDockVideoCallHold btnChatDockVideoCallAfterButton d-none"
                        data-bs-toggle="tooltip"
                        data-bs-title="Hold"
                      >
                        <input type="checkbox" id="ChatDockVideoCallHold" />
                        <label htmlFor="ChatDockVideoCallHold" className="checkmark"></label>
                      </span>
                      <button type="button" className="btn btnChatDockVideoAudioSettings">
                        <i
                          className="bi bi-gear-fill"
                          data-bs-toggle="tooltip"
                          data-bs-title="Video / Audio Settings"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ratio ratio-16x9">
              <div className="">
                <div className="chatDockStartVideoCallContainer">
                  <div className="btn-group btnAddNewDropdown btnAddNewDropdownSplit my-3 ">
                    <button type="button" className="btn btn-primary btn-lg btnChatDockVideoCall">
                      <i className="bi bi-play-circle" /> Start New Video Meeting
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary btn-lg dropdown-toggle dropdown-toggle-split"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="visually-hidden">Toggle Dropdown</span>
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item btnVideoChatOptMeetNow" href="javascript:">
                          <i className="bi bi-envelope-fill" /> Meet Now &amp; Email Meeting URL
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item btnVideoChatOptMeetNow" href="javascript:">
                          <i className="bi bi-phone-fill" /> Meet Now &amp; SMS Meeting URL
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item btnVideoChatOptCreateMeeting" href="javascript:">
                          <i className="bi bi-link-45deg" /> Create Meeting Link for Future
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item btnVideoChatOptScheduleMeeting" href="javascript:">
                          <i className="bi bi-calendar3" /> Schedule a Meeting
                        </a>
                      </li>
                    </ul>
                  </div>

                  <button
                    className="btn btn-default btn-lg ms-2 btnChatDockVideoRecording"
                    data-bs-toggle="tooltip"
                    title="Record Video Clip"
                  >
                    <i className="bi bi-record-fill" />
                  </button>
                </div>
                <div className="ChatDockVideocallProcessing d-none text-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
                <div className="ChatDockVideocallWebCamContainer d-none text-center">
                  {/*<video id="videoCam" playsinline autoplay muted></video>*/}
                  {/*<div style="color: #ff6565" id="videoCamErrorMsg"></div>*/}
                  <p className="fs-5 fw-bold">Awaiting your guest.</p>
                  <p>
                    <a href="https://SwiftMeeting.com/xyz123" target="_blank">
                      SwiftMeeting.com/xyz123
                    </a>
                  </p>
                  <div>
                    <div className="btn-group btnAddNewDropdown btnAddNewDropdownSplit my-3 ">
                      <button type="button" className="btn btn-primary">
                        <i className="bi bi-envelope-fill" /> Email Meeting Link
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="visually-hidden">Toggle Dropdown</span>
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a
                            className="dropdown-item"
                            data-type="Email"
                            data-value="amitabh@domain.com"
                            href="javascript:"
                          >
                            <i className="bi bi-envelope-fill" /> Email to amitabh@domain.com
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            data-type="Email"
                            data-value="amitabh2@domain.com"
                            href="javascript:"
                          >
                            <i className="bi bi-envelope-fill" /> Email to amitabh2@domain.com
                          </a>
                        </li>
                      </ul>
                    </div>
                    <button className="btn btn-primary">
                      <i className="bi bi-phone-fill" /> SMS Meeting Link
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*DIRECTIONS*/}
          <div className="ChatDockOpt ChatDockDirectionsOpt d-none">
            <div className="text-center py-3">
              <div className="btn-group dropup">
                <button type="button" className="btn btn-primary btnChatDockGetDirection">
                  Get Directions
                </button>
                <button
                  type="button"
                  className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="visually-hidden">Toggle Dropdown</span>
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#">
                      From{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/*POSTAL MAIL*/}
          <div className="ChatDockOpt ChatDockPostalMailOpt d-none">
            <div className="py-3">
              <div className="row row-cols-lg-auto g-2 align-items-center justify-content-center">
                <div className="col-12 col-sm-4">
                  <select className="form-select ChatDockPostalMailType">
                    <option value="Shipping Label">Shipping Label</option>
                    <option value="Mail-Merge Doc">Mail-Merge Doc</option>
                    <option value="PDF">PDF</option>
                  </select>
                </div>
                <div className="col-12 col-sm-4 ChatDockPostalMailTypeDropdown d-none">
                  <select className="form-select">
                    <option value="">== DOC ==</option>
                    <option value="Doc 1">Doc 1</option>
                  </select>
                </div>
                <div className="col-12  col-sm-4 ChatDockPostalMailPrintBtnContainer">
                  <button className="btn btn-primary btbChatDockPostalMailPrintSend">
                    <i className="bi bi-printer-fill" /> Print <i className="bi bi-send-fill" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/*REPORT*/}
          <div className="ChatDockOpt ChatDockReportOpt d-none">
            <div className="text-center py-3">
              {/*call to data source and insert as note */}
              <button type="button" className="btn btn-primary">
                <i className="bi bi-bar-chart-line-fill" /> Generate Report
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*History dock end */}
    </div>
  )
}

export default WorkroomChatPanel
