import React from "react"
import { Accordion } from "react-bootstrap"

const TaskPanel = () => {
  return (
    <div className="col-lg-12 mb-3" id="task_panel">
      <Accordion defaultActiveKey="0" className="accordion-item panel panel-grey" alwaysOpen>
        <Accordion.Header>
          <i className="bi bi-check2-square me-2" /> Tasks & Projects
        </Accordion.Header>
        <Accordion.Body>
          <div className="accordion-body">
            <div className="row g-0">
              <div className="col">
                <div className="row tabularMenuContainer">
                  <div className="col"></div>
                  <div className="col-sm-9">
                    <div className="float-end">
                      <ul className="nav nav-tabs swiftCloudTabs d-md-flex" role="tablist">
                        <li className="nav-item">
                          <div className="btn-group btnAddNewDropdown">
                            <button
                              type="button"
                              className="btn btn-primary"
                              data-bs-toggle="modal"
                              data-bs-target="#addNewTaskModal"
                            >
                              <i className="bi bi-plus-lg" />{" "}
                              <span className="d-none d-lg-inline">Add New</span>
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span className="visually-hidden">Toggle Dropdown</span>
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <a className="dropdown-item" data-bs-toggle="modal" href="#addNewTaskModal">
                                  <i className="bi bi-check-square" /> Task / Project
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  href="#newFolderWorkroomModal"
                                >
                                  <i className="bi bi-folder-fill" /> Folder /{" "}
                                  <i className="bi bi-people-fill" /> Workroom
                                </a>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li className="nav-item" role="presentation">
                          <a
                            className="nav-link active"
                            id="PrioritizedListView-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#PrioritizedListView"
                            role="tab"
                            aria-controls="PrioritizedListView"
                            aria-selected="false"
                          >
                            <span data-bs-toggle="tooltip" data-bs-title="Prioritized List View">
                              <i className="bi bi-list-ol" />
                            </span>
                          </a>
                        </li>
                        <li className="nav-item" role="presentation">
                          <a
                            className="nav-link"
                            id="HierarchyTreeView-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#HierarchyTreeView"
                            role="tab"
                            aria-controls="HierarchyTreeView"
                            aria-selected="false"
                          >
                            <span data-bs-toggle="tooltip" data-bs-title="Hierarchy Tree View">
                              <i className="bi bi-diagram-3-fill" />
                            </span>
                          </a>
                        </li>
                        <li className="nav-item nav-item-trash" role="presentation">
                          <a
                            className="nav-link"
                            href="#"
                            data-bs-toggle="tooltip"
                            data-placement="top"
                            title="Trash"
                          >
                            <i className="bi bi-trash-fill" />
                          </a>
                        </li>
                      </ul>

                      {/*mobile view*/}
                      <ul
                        className="nav nav-tabs swiftCloudTabs mobileTabs d-md-none d-flex mb-1"
                        role="tablist"
                      >
                        <li className="nav-item">
                          <div className="btn-group btnAddNewDropdown" role="group">
                            <button
                              id="btnGroupDrop2"
                              type="button"
                              className="btn btn-primary dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="bi bi-plus-lg" />
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="btnGroupDrop2">
                              <li>
                                <a className="dropdown-item" data-bs-toggle="modal" href="#addNewTaskModal">
                                  <i className="bi bi-check-square" /> Task / Project
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  href="#newFolderWorkroomModal"
                                >
                                  <i className="bi bi-folder-fill" /> Folder /{" "}
                                  <i className="bi bi-people-fill" /> Workroom
                                </a>
                              </li>
                            </ul>
                          </div>

                          <div className="btn-group btnDriveView" role="group">
                            <button
                              id="btnGroupDrop3"
                              type="button"
                              className="btn btn-default dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="bi bi-list-ol" />
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="btnGroupDrop3">
                              <li>
                                <a
                                  className="dropdown-item"
                                  data-bs-toggle="tab"
                                  data-bs-target="#PrioritizedListView"
                                  role="tab"
                                  aria-controls="PrioritizedListView"
                                  aria-selected="false"
                                >
                                  <i className="bi bi-list-ol" /> Prioritized List View
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  data-bs-toggle="tab"
                                  data-bs-target="#HierarchyTreeView"
                                  role="tab"
                                  aria-controls="HierarchyTreeView"
                                  aria-selected="false"
                                >
                                  <i className="bi bi-diagram-3-fill" /> Hierarchy Tree View
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  <i className="bi bi-trash-fill" /> Trash
                                </a>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="PrioritizedListView"
                        role="tabpanel"
                        aria-labelledby="PrioritizedListView-tab"
                      >
                        <div className="table-responsive">
                          <table
                            id="prioritized_list_view_table"
                            className="table dataTable"
                            cellSpacing="0"
                            width="100%"
                          >
                            <thead>
                              <tr>
                                <th width="5%"></th>
                                <th width="40%">
                                  Task{" "}
                                  <button
                                    type="button"
                                    className="btn btn-sm btnTHSoryByTags float-end me-3"
                                    onClick="event.stopPropagation();"
                                    data-bs-toggle="modal"
                                    data-bs-target="#ContactsSortByTagModal"
                                    title="Advanced Tag Search"
                                  >
                                    <span data-bs-toggle="tooltip" title="Advanced Tag Search">
                                      <i className="bi bi-tags-fill" />
                                    </span>
                                  </button>
                                </th>
                                <th width="25%" className="d-none d-md-table-cell td-urgency">
                                  Urgency
                                </th>
                                <th width="30%" className="d-none d-lg-table-cell">
                                  Last Activity / Status
                                </th>
                              </tr>
                              <tr className="d-none table_action_container">
                                <td colSpan="4" className="text-left">
                                  <div className="batch-action-container">
                                    <button className="btn btn-trash">
                                      <i className="bi bi-trash-fill" /> Move to Trash
                                    </button>
                                    <button
                                      className="btn btn-default"
                                      data-bs-toggle="tooltip"
                                      data-placement="top"
                                      title="Archive (Searchable but Hidden)"
                                    >
                                      <i className="bi bi-archive-fill" /> Archive
                                    </button>
                                    <button className="btn btn-secondary">
                                      <i className="bi bi-tags-fill" /> Apply Tag(s)
                                    </button>
                                    <button className="btn btn-success">
                                      <i className="bi bi-person-plus-fill" /> Manage Sharing
                                    </button>
                                    <button className="btn btn-primary">
                                      <i className="bi bi-unlock-fill" /> Manage Permissions
                                    </button>
                                    <button className="btn btn-secondary">
                                      <i className="bi bi-arrows-move" /> Move To...
                                    </button>
                                  </div>
                                </td>
                              </tr>
                              <tr className="d-none filter_table_action_container">
                                <td colSpan="4" className="">
                                  <div className="filter-batch-action-container">
                                    <div className="row">
                                      <div className="col-lg-9 col-sm-12">
                                        <form id="filterViewForm" className="filterViewRowForm">
                                          <div className="row row-cols-lg-auto g-2 align-items-center mb-3">
                                            <div className="col-12">Show me</div>
                                            <div className="col-12">
                                              <select
                                                className="form-select helpDeskTaskFilter filesOpt"
                                                name="mainCriteria"
                                                id="helpDeskTaskFilterSelect"
                                                data-toggle="selectpicker"
                                              >
                                                <option
                                                  value="people"
                                                  data-icon="fa-user"
                                                  selected="selected"
                                                >
                                                  Contacts
                                                </option>
                                                <option value="tasks&amp;Projects" data-icon="fa-check">
                                                  Task / Job / Sales Opportunities
                                                </option>
                                                <option
                                                  value="Messages&amp;Notifications"
                                                  data-icon="fa-inbox"
                                                >
                                                  Messages &amp; Notifications
                                                </option>
                                                <option value="calendar" data-icon="fa-calendar">
                                                  Calendar Events
                                                </option>
                                                <option value="accounting" data-icon="fa-exchange">
                                                  Accounting Records
                                                </option>
                                                <option value="agreements" data-icon="fa-handshake">
                                                  Agreements
                                                </option>
                                                <option value="filesWorkrooms" data-icon="fa-folder">
                                                  Files / Workrooms / Custom Assets
                                                </option>
                                                <option value="timeclockRecords" data-icon="fa-clock">
                                                  Timeclock Records
                                                </option>
                                              </select>
                                            </div>
                                            <div className="col-12">that are</div>
                                            <div className="col-12">
                                              <select className="form-select inputFeedType" name="options[]">
                                                <option value="opt_1">Tagged with...</option>
                                                <option value="opt_3">Search Results Include</option>
                                                <option value="ScheduledInto">Scheduled Into...</option>
                                              </select>
                                            </div>
                                          </div>
                                          <div className="row mb-3">
                                            <div className="col-lg-12">
                                              <div className="filterOpts" id="newfilter_1">
                                                <div className="row">
                                                  <div className="col-lg-12">
                                                    <div className="columnFilter opt_1">
                                                      <div className="row">
                                                        <div className="col-lg-12 sortbytag_row">
                                                          <div className="input-group mb-3">
                                                            <select
                                                              name="FV_sortbytag_with_or_without"
                                                              id="FV_sortbytag_with_or_without"
                                                              className="form-select"
                                                            >
                                                              <option value="Tagged with">Tagged with</option>
                                                              <option value="Not Tagged with">
                                                                Not Tagged with
                                                              </option>
                                                            </select>
                                                            <input
                                                              type="text"
                                                              name="FV_sortbytag_input"
                                                              id="FV_sortbytag_input"
                                                              className="form-control"
                                                              value="tag"
                                                            />
                                                            <select
                                                              name="FV_sortbytag_score_opt"
                                                              id="FV_sortbytag_score_opt"
                                                              className="form-select"
                                                            >
                                                              <option value="Any">Any TagScore</option>
                                                              <option value="Above">TagScore Above</option>
                                                              <option value="Below">TagScore Below</option>
                                                            </select>
                                                            <input
                                                              type="number"
                                                              name="FV_sortbytag_score_input"
                                                              id="FV_sortbytag_score_input"
                                                              className="form-control d-none"
                                                              placeholder="Value"
                                                              value="50"
                                                            />
                                                            <select
                                                              name="FV_sortbytag_asc_desc"
                                                              className="form-select"
                                                            >
                                                              <option value="Ascending">Ascending</option>
                                                              <option value="Descending">Descending</option>
                                                              <option value="Newest">Newest</option>
                                                              <option value="Oldest">Oldest</option>
                                                            </select>
                                                          </div>
                                                        </div>
                                                        <div className="col-lg-12 mb-3 FV_sortbytag_search_within_row display-none">
                                                          <input
                                                            type="text"
                                                            name="FV_sortbytag_search_within"
                                                            id="FV_sortbytag_search_within"
                                                            placeholder="Search Within Results"
                                                            className="form-control"
                                                          />
                                                          <a
                                                            href="javascript:"
                                                            className="btnRemoveSearchWithinTag"
                                                            data-bs-toggle="tooltip"
                                                            title="Remove search"
                                                          >
                                                            <i className="bi bi-x" />
                                                          </a>
                                                        </div>
                                                        <div className="col-lg-12 FV_addsortbytag_search_within_row">
                                                          <button
                                                            type="button"
                                                            className="btnAddNewGreenDashed btnAddSortbyTagFilerCondition"
                                                            data-bs-toggle="tooltip"
                                                            title="Add filter condition"
                                                          >
                                                            <i className="bi bi-plus-lg" />
                                                          </button>
                                                          <button
                                                            type="button"
                                                            className="btnAddNewGreenDashed btnAddSortbyTagSearchCondition"
                                                            data-bs-toggle="tooltip"
                                                            title="Search"
                                                          >
                                                            <i className="bi bi-search" />
                                                          </button>
                                                          <div className="hidden_FV_sortbytag_filter_condition_row display-none">
                                                            <div className="input-group mb-3">
                                                              <select
                                                                name="sortbytagSearchCondition"
                                                                className="form-select"
                                                              >
                                                                <option value="OR">OR Tag...</option>
                                                                <option value="AND">AND Tag...</option>
                                                                <option value="NOT">NOT Tag...</option>
                                                              </select>
                                                              <input
                                                                type="text"
                                                                className="form-control FV_sortbytagSearchTaginput"
                                                              />
                                                            </div>
                                                            <a
                                                              href="javascript:"
                                                              className="btnRemoveSortbyTagFilter"
                                                              data-bs-toggle="tooltip"
                                                              title="Remove filter"
                                                            >
                                                              <i className="bi bi-x-lg" />
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="columnFilter opt_3 d-none">
                                                      <input
                                                        type="text"
                                                        name="search_keyword"
                                                        value=""
                                                        className="form-control"
                                                      />
                                                    </div>
                                                    <div className="columnFilter ScheduledInto d-none">
                                                      <select className="form-control form-select">
                                                        <option value="All Calendars">
                                                          All Calendars (Blended View)
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="form-group text-center mb-0 filteredViewBtnGroup">
                                            <button
                                              type="button"
                                              className="btn btn-default btnCloseFilteredView"
                                            >
                                              <i className="bi bi-x-lg" /> Cancel
                                            </button>
                                            <button
                                              className="btn btn-secondary btnSaveFilteredView"
                                              data-toggle="modal"
                                              data-target="#newFilterModal"
                                            >
                                              <i className="bi bi-check2" /> Save Filtered View{" "}
                                              <i className="bi bi-bullseye" />
                                            </button>
                                            <button className="btn btn-primary" id="viewFilterResult">
                                              <i className="bi bi-check2" /> View Results{" "}
                                              <i className="bi bi-bullseye" />
                                            </button>
                                            <button className="btn btnDeleteFilterView display-none">
                                              <i className="bi bi-trash-fill" /> Delete Filtered View
                                            </button>
                                          </div>
                                        </form>
                                      </div>
                                      <div className="col-lg-3 col-sm-12 savedFilterdView mt-3 mt-lg-0">
                                        <h4>Saved Filtered Views</h4>
                                        <ul id="savedFilteredList">
                                          <li>
                                            <a href="#" className="btn btn-default">
                                              <i className="bi bi-folder-fill" /> Real estate listing
                                            </a>
                                            <a
                                              href="javascript:"
                                              data-bs-toggle="tooltip"
                                              title="Edit"
                                              className="editFiltered"
                                            >
                                              <i className="bi bi-pencil-square" />
                                            </a>
                                            <a
                                              href="javascript:"
                                              data-bs-toggle="tooltip"
                                              title="Delete"
                                              className="deleteFilter"
                                            >
                                              <i className="bi bi-trash-fill" />
                                            </a>
                                          </li>
                                          <li>
                                            <a href="#" className="btn btn-default">
                                              <i className="bi bi-check2" /> Plumber Transaction
                                            </a>
                                            <a
                                              href="javascript:"
                                              data-bs-toggle="tooltip"
                                              title="Edit"
                                              className="editFiltered"
                                            >
                                              <i className="bi bi-pencil-square" />
                                            </a>
                                            <a
                                              href="javascript:"
                                              data-bs-toggle="tooltip"
                                              title="Delete"
                                              className="deleteFilter"
                                            >
                                              <i className="bi bi-trash-fill" />
                                            </a>
                                          </li>
                                          <li>
                                            <a href="#" className="btn btn-default">
                                              <i className="bi bi-person-fill" /> Doctors Appointment
                                            </a>
                                            <a
                                              href="javascript:"
                                              data-bs-toggle="tooltip"
                                              title="Edit"
                                              className="editFiltered"
                                            >
                                              <i className="bi bi-pencil-square" />
                                            </a>
                                            <a
                                              href="javascript:"
                                              data-bs-toggle="tooltip"
                                              title="Delete"
                                              className="deleteFilter"
                                            >
                                              <i className="bi bi-trash-fill" />
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="folderRow">
                                <td className="text-center tableColChkDel">
                                  <div className="tableColChkDelBox">
                                    <input
                                      type="checkbox"
                                      name="fileid[]"
                                      id="ID1"
                                      className="css-checkbox"
                                    />
                                    <label htmlFor="ID1" className="css-checkbox-label"></label>
                                    <span className="tableColChkSpan folderIconBg">
                                      <i className="bi bi-folder-fill" />
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <a href="#">
                                    <strong>Task folder</strong>
                                  </a>
                                  <div className="SwiftCloudTableTags">
                                    <ul>
                                      <li>
                                        <a href="javaScript:void(0);" data-tagname="Tag1">
                                          Tag1
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                                <td width="10%" className="d-none d-md-table-cell colTaskUrgency">
                                  <button type="button" className="btn urgencyStatus_on_hold">
                                    <i className="bi bi-moon" /> On Hold
                                  </button>
                                </td>
                                <td className="d-none d-lg-table-cell">
                                  <div className="lastActivityBox">
                                    <time className="timeago" dateTime="2022-05-01"></time>
                                  </div>
                                  <div className="statusActionBox">
                                    <button type="button" className="btnTaskStatus taskStatus_folder">
                                      <i className="bi bi-folder-fill" />
                                    </button>
                                    <a
                                      href="javascript:"
                                      className="btn btn-delete"
                                      data-bs-toggle="tooltip"
                                      title="Delete"
                                    >
                                      <i className="bi bi-trash-fill" />
                                    </a>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center tableColChkDel">
                                  <div className="tableColChkDelBox">
                                    <input
                                      type="checkbox"
                                      name="fileid[]"
                                      id="ID2"
                                      className="css-checkbox"
                                    />
                                    <label htmlFor="ID2" className="css-checkbox-label"></label>
                                    <span className="tableColChkSpan btn-secondary">
                                      <i className="bi bi-house-door-fill" />
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <a href="#">Task 1</a>
                                  <div className="SwiftCloudTableTags">
                                    <ul>
                                      <li>
                                        <a href="javaScript:void(0);" data-tagname="Tag2">
                                          Tag2
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                                <td width="10%" className="d-none d-md-table-cell colTaskUrgency">
                                  <button type="button" className="btn urgencyStatus_slowDay">
                                    <i className="bi bi-hourglass" /> Slow Day
                                  </button>
                                </td>
                                <td className="d-none d-lg-table-cell">
                                  <div className="lastActivityBox">
                                    <time className="timeago" dateTime="2022-05-01"></time>
                                  </div>
                                  <div className="statusActionBox">
                                    <div className="dropdown d-inlie-block">
                                      <button
                                        className="btn btnTaskStatus taskStatus_new"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i className="bi bi-app" />
                                      </button>
                                      <ul className="dropdown-menu hiddenStatus">
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_new">
                                              <i className="bi bi-app" />
                                            </span>{" "}
                                            New
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_claimed">
                                              <i className="bi bi-clock" />
                                            </span>{" "}
                                            Claimed
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_in_progress">
                                              <i className="bi bi-hourglass-split" />
                                            </span>{" "}
                                            In Process
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_pending_verification">
                                              <i className="bi bi-check2-square" />
                                            </span>{" "}
                                            Complete Pending Verification
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_revision">
                                              <i className="bi bi-exclamation-triangle-fill" />
                                            </span>{" "}
                                            Revisions
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_closed_successful">
                                              <i className="bi bi-flag-fill" />
                                            </span>{" "}
                                            Closed Successful
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_clarification_needed">
                                              <i className="bi bi-chat-fill" />
                                            </span>{" "}
                                            Specs / Clarification Needed
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_deliverables_required">
                                              <i className="bi bi-cloud-arrow-up-fill" />
                                            </span>{" "}
                                            Deliverables Required
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_prerequisite">
                                              <i className="bi bi-clock" />
                                            </span>{" "}
                                            Prerequisite / Dependency
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                    <a
                                      href="javascript:"
                                      className="btn btn-delete"
                                      data-bs-toggle="tooltip"
                                      title="Delete"
                                    >
                                      <i className="bi bi-trash-fill" />
                                    </a>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center tableColChkDel">
                                  <div className="tableColChkDelBox">
                                    <input
                                      type="checkbox"
                                      name="fileid[]"
                                      id="ID3"
                                      className="css-checkbox"
                                    />
                                    <label htmlFor="ID3" className="css-checkbox-label"></label>
                                    <span className="tableColChkSpan btn-secondary">
                                      <i className="bi bi-person-square" />
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <a href="#">Task 2</a>
                                </td>
                                <td width="10%" className="d-none d-md-table-cell colTaskUrgency">
                                  <button type="button" className="btn urgencyStatus_important">
                                    <i className="bi bi-star" /> Important
                                  </button>
                                </td>
                                <td className="d-none d-lg-table-cell">
                                  <div className="lastActivityBox">
                                    <time className="timeago" dateTime="2022-05-01"></time>
                                  </div>
                                  <div className="statusActionBox">
                                    <div className="dropdown d-inlie-block">
                                      <button
                                        className="btn btnTaskStatus taskStatus_claimed"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i className="bi bi-clock" />
                                      </button>
                                      <ul className="dropdown-menu hiddenStatus">
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_new">
                                              <i className="bi bi-app" />
                                            </span>{" "}
                                            New
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_claimed">
                                              <i className="bi bi-clock" />
                                            </span>{" "}
                                            Claimed
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_in_progress">
                                              <i className="bi bi-hourglass-split" />
                                            </span>{" "}
                                            In Process
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_pending_verification">
                                              <i className="bi bi-check2-square" />
                                            </span>{" "}
                                            Complete Pending Verification
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_revision">
                                              <i className="bi bi-exclamation-triangle-fill" />
                                            </span>{" "}
                                            Revisions
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_closed_successful">
                                              <i className="bi bi-flag-fill" />
                                            </span>{" "}
                                            Closed Successful
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_clarification_needed">
                                              <i className="bi bi-chat-fill" />
                                            </span>{" "}
                                            Specs / Clarification Needed
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_deliverables_required">
                                              <i className="bi bi-cloud-arrow-up-fill" />
                                            </span>{" "}
                                            Deliverables Required
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_prerequisite">
                                              <i className="bi bi-clock" />
                                            </span>{" "}
                                            Prerequisite / Dependency
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                    <a
                                      href="javascript:"
                                      className="btn btn-delete"
                                      data-bs-toggle="tooltip"
                                      title="Delete"
                                    >
                                      <i className="bi bi-trash-fill" />
                                    </a>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center tableColChkDel">
                                  <div className="tableColChkDelBox">
                                    <input
                                      type="checkbox"
                                      name="fileid[]"
                                      id="ID4"
                                      className="css-checkbox"
                                    />
                                    <label htmlFor="ID4" className="css-checkbox-label"></label>
                                    <span className="tableColChkSpan btn-secondary">
                                      <i className="bi bi-check2-square" />
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <a href="#">Task 3</a>
                                </td>
                                <td width="10%" className="d-none d-md-table-cell colTaskUrgency">
                                  <button type="button" className="btn urgencyStatus_medium">
                                    <i className="bi bi-star-fill" /> Medium
                                  </button>
                                </td>
                                <td className="d-none d-lg-table-cell">
                                  <div className="lastActivityBox">
                                    <time className="timeago" dateTime="2022-05-01"></time>
                                  </div>
                                  <div className="statusActionBox">
                                    <div className="dropdown d-inlie-block">
                                      <button
                                        className="btn btnTaskStatus taskStatus_in_progress"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i className="bi bi-hourglass-split" />
                                      </button>
                                      <ul className="dropdown-menu hiddenStatus">
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_new">
                                              <i className="bi bi-app" />
                                            </span>{" "}
                                            New
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_claimed">
                                              <i className="bi bi-clock" />
                                            </span>{" "}
                                            Claimed
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_in_progress">
                                              <i className="bi bi-hourglass-split" />
                                            </span>{" "}
                                            In Process
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_pending_verification">
                                              <i className="bi bi-check2-square" />
                                            </span>{" "}
                                            Complete Pending Verification
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_revision">
                                              <i className="bi bi-exclamation-triangle-fill" />
                                            </span>{" "}
                                            Revisions
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_closed_successful">
                                              <i className="bi bi-flag-fill" />
                                            </span>{" "}
                                            Closed Successful
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_clarification_needed">
                                              <i className="bi bi-chat-fill" />
                                            </span>{" "}
                                            Specs / Clarification Needed
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_deliverables_required">
                                              <i className="bi bi-cloud-arrow-up-fill" />
                                            </span>{" "}
                                            Deliverables Required
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_prerequisite">
                                              <i className="bi bi-clock" />
                                            </span>{" "}
                                            Prerequisite / Dependency
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                    <a
                                      href="javascript:"
                                      className="btn btn-delete"
                                      data-bs-toggle="tooltip"
                                      title="Delete"
                                    >
                                      <i className="bi bi-trash-fill" />
                                    </a>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center tableColChkDel">
                                  <div className="tableColChkDelBox">
                                    <input
                                      type="checkbox"
                                      name="fileid[]"
                                      id="ID5"
                                      className="css-checkbox"
                                    />
                                    <label htmlFor="ID5" className="css-checkbox-label"></label>
                                    <span className="tableColChkSpan btn-secondary">
                                      <i className="bi bi-house-door-fill" />
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <a href="#">Task 4</a>
                                </td>
                                <td width="10%" className="d-none d-md-table-cell colTaskUrgency">
                                  <button type="button" className="btn urgencyStatus_asap">
                                    <i className="bi bi-heart-fill" /> ASAP
                                  </button>
                                </td>
                                <td className="d-none d-lg-table-cell">
                                  <div className="lastActivityBox">
                                    <time className="timeago" dateTime="2022-05-01"></time>
                                  </div>
                                  <div className="statusActionBox">
                                    <div className="dropdown d-inlie-block">
                                      <button
                                        className="btn btnTaskStatus taskStatus_pending_verification"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i className="bi bi-check2-square" />
                                      </button>
                                      <ul className="dropdown-menu hiddenStatus">
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_new">
                                              <i className="bi bi-app" />
                                            </span>{" "}
                                            New
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_claimed">
                                              <i className="bi bi-clock" />
                                            </span>{" "}
                                            Claimed
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_in_progress">
                                              <i className="bi bi-hourglass-split" />
                                            </span>{" "}
                                            In Process
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_pending_verification">
                                              <i className="bi bi-check2-square" />
                                            </span>{" "}
                                            Complete Pending Verification
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_revision">
                                              <i className="bi bi-exclamation-triangle-fill" />
                                            </span>{" "}
                                            Revisions
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_closed_successful">
                                              <i className="bi bi-flag-fill" />
                                            </span>{" "}
                                            Closed Successful
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_clarification_needed">
                                              <i className="bi bi-chat-fill" />
                                            </span>{" "}
                                            Specs / Clarification Needed
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_deliverables_required">
                                              <i className="bi bi-cloud-arrow-up-fill" />
                                            </span>{" "}
                                            Deliverables Required
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_prerequisite">
                                              <i className="bi bi-clock" />
                                            </span>{" "}
                                            Prerequisite / Dependency
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                    <a
                                      href="javascript:"
                                      className="btn btn-delete"
                                      data-bs-toggle="tooltip"
                                      title="Delete"
                                    >
                                      <i className="bi bi-trash-fill" />
                                    </a>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center tableColChkDel">
                                  <div className="tableColChkDelBox">
                                    <input
                                      type="checkbox"
                                      name="fileid[]"
                                      id="ID6"
                                      className="css-checkbox"
                                    />
                                    <label htmlFor="ID6" className="css-checkbox-label"></label>
                                    <span className="tableColChkSpan btn-secondary">
                                      <i className="bi bi-house-door-fill" />
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <a href="#">Task 5</a>
                                </td>
                                <td width="10%" className="d-none d-md-table-cell colTaskUrgency">
                                  <button type="button" className="btn urgencyStatus_high_priority">
                                    <i className="bi bi-exclamation-lg" /> High Priority
                                  </button>
                                </td>
                                <td className="d-none d-lg-table-cell">
                                  <div className="lastActivityBox">
                                    <time className="timeago" dateTime="2022-05-01"></time>
                                  </div>
                                  <div className="statusActionBox">
                                    <div className="dropdown d-inlie-block">
                                      <button
                                        className="btn btnTaskStatus taskStatus_revision"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i className="bi bi-exclamation-triangle-fill" />
                                      </button>
                                      <ul className="dropdown-menu hiddenStatus">
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_new">
                                              <i className="bi bi-app" />
                                            </span>{" "}
                                            New
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_claimed">
                                              <i className="bi bi-clock" />
                                            </span>{" "}
                                            Claimed
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_in_progress">
                                              <i className="bi bi-hourglass-split" />
                                            </span>{" "}
                                            In Process
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_pending_verification">
                                              <i className="bi bi-check2-square" />
                                            </span>{" "}
                                            Complete Pending Verification
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_revision">
                                              <i className="bi bi-exclamation-triangle-fill" />
                                            </span>{" "}
                                            Revisions
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_closed_successful">
                                              <i className="bi bi-flag-fill" />
                                            </span>{" "}
                                            Closed Successful
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_clarification_needed">
                                              <i className="bi bi-chat-fill" />
                                            </span>{" "}
                                            Specs / Clarification Needed
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_deliverables_required">
                                              <i className="bi bi-cloud-arrow-up-fill" />
                                            </span>{" "}
                                            Deliverables Required
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_prerequisite">
                                              <i className="bi bi-clock" />
                                            </span>{" "}
                                            Prerequisite / Dependency
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                    <a
                                      href="javascript:"
                                      className="btn btn-delete"
                                      data-bs-toggle="tooltip"
                                      title="Delete"
                                    >
                                      <i className="bi bi-trash-fill" />
                                    </a>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center tableColChkDel">
                                  <div className="tableColChkDelBox">
                                    <input
                                      type="checkbox"
                                      name="fileid[]"
                                      id="ID7"
                                      className="css-checkbox"
                                    />
                                    <label htmlFor="ID7" className="css-checkbox-label"></label>
                                    <span className="tableColChkSpan btn-secondary">
                                      <i className="bi bi-house-door-fill" />
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <a href="#">Task 6</a>
                                </td>
                                <td width="10%" className="d-none d-md-table-cell colTaskUrgency">
                                  <button type="button" className="btn urgencyStatus_urgent">
                                    <i className="bi bi-exclamation-circle-fill" /> Urgent
                                  </button>
                                </td>
                                <td className="d-none d-lg-table-cell">
                                  <div className="lastActivityBox">
                                    <time className="timeago" dateTime="2022-05-01"></time>
                                  </div>
                                  <div className="statusActionBox">
                                    <div className="dropdown d-inlie-block">
                                      <button
                                        className="btn btnTaskStatus taskStatus_closed_successful"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i className="bi bi-flag-fill" />
                                      </button>
                                      <ul className="dropdown-menu hiddenStatus">
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_new">
                                              <i className="bi bi-app" />
                                            </span>{" "}
                                            New
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_claimed">
                                              <i className="bi bi-clock" />
                                            </span>{" "}
                                            Claimed
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_in_progress">
                                              <i className="bi bi-hourglass-split" />
                                            </span>{" "}
                                            In Process
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_pending_verification">
                                              <i className="bi bi-check2-square" />
                                            </span>{" "}
                                            Complete Pending Verification
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_revision">
                                              <i className="bi bi-exclamation-triangle-fill" />
                                            </span>{" "}
                                            Revisions
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_closed_successful">
                                              <i className="bi bi-flag-fill" />
                                            </span>{" "}
                                            Closed Successful
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_clarification_needed">
                                              <i className="bi bi-chat-fill" />
                                            </span>{" "}
                                            Specs / Clarification Needed
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_deliverables_required">
                                              <i className="bi bi-cloud-arrow-up-fill" />
                                            </span>{" "}
                                            Deliverables Required
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_prerequisite">
                                              <i className="bi bi-clock" />
                                            </span>{" "}
                                            Prerequisite / Dependency
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                    <a
                                      href="javascript:"
                                      className="btn btn-delete"
                                      data-bs-toggle="tooltip"
                                      title="Delete"
                                    >
                                      <i className="bi bi-trash-fill" />
                                    </a>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center tableColChkDel">
                                  <div className="tableColChkDelBox">
                                    <input
                                      type="checkbox"
                                      name="fileid[]"
                                      id="ID8"
                                      className="css-checkbox"
                                    />
                                    <label htmlFor="ID8" className="css-checkbox-label"></label>
                                    <span className="tableColChkSpan btn-secondary">
                                      <i className="bi bi-house-door-fill" />
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <a href="#">Task 7</a>
                                </td>
                                <td width="10%" className="d-none d-md-table-cell colTaskUrgency">
                                  <button type="button" className="btn urgencyStatus_severe">
                                    <i className="bi bi-exclamation-triangle-fill" /> Severe
                                  </button>
                                </td>
                                <td className="d-none d-lg-table-cell">
                                  <div className="lastActivityBox">
                                    <time className="timeago" dateTime="2022-05-01"></time>
                                  </div>
                                  <div className="statusActionBox">
                                    <div className="dropdown d-inlie-block">
                                      <button
                                        className="btn btnTaskStatus taskStatus_clarification_needed"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i className="bi bi-chat-fill" />
                                      </button>
                                      <ul className="dropdown-menu hiddenStatus">
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_new">
                                              <i className="bi bi-app" />
                                            </span>{" "}
                                            New
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_claimed">
                                              <i className="bi bi-clock" />
                                            </span>{" "}
                                            Claimed
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_in_progress">
                                              <i className="bi bi-hourglass-split" />
                                            </span>{" "}
                                            In Process
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_pending_verification">
                                              <i className="bi bi-check2-square" />
                                            </span>{" "}
                                            Complete Pending Verification
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_revision">
                                              <i className="bi bi-exclamation-triangle-fill" />
                                            </span>{" "}
                                            Revisions
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_closed_successful">
                                              <i className="bi bi-flag-fill" />
                                            </span>{" "}
                                            Closed Successful
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_clarification_needed">
                                              <i className="bi bi-chat-fill" />
                                            </span>{" "}
                                            Specs / Clarification Needed
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_deliverables_required">
                                              <i className="bi bi-cloud-arrow-up-fill" />
                                            </span>{" "}
                                            Deliverables Required
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_prerequisite">
                                              <i className="bi bi-clock" />
                                            </span>{" "}
                                            Prerequisite / Dependency
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                    <a
                                      href="javascript:"
                                      className="btn btn-delete"
                                      data-bs-toggle="tooltip"
                                      title="Delete"
                                    >
                                      <i className="bi bi-trash-fill" />
                                    </a>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center tableColChkDel">
                                  <div className="tableColChkDelBox">
                                    <input
                                      type="checkbox"
                                      name="fileid[]"
                                      id="ID9"
                                      className="css-checkbox"
                                    />
                                    <label htmlFor="ID9" className="css-checkbox-label"></label>
                                    <span className="tableColChkSpan btn-secondary">
                                      <i className="bi bi-house-door-fill" />
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <a href="#">Task 8</a>
                                </td>
                                <td width="10%" className="d-none d-md-table-cell colTaskUrgency">
                                  <button type="button" className="btn urgencyStatus_critical">
                                    <i className="bi bi-fire" /> Critical
                                  </button>
                                </td>
                                <td className="d-none d-lg-table-cell">
                                  <div className="lastActivityBox">
                                    <time className="timeago" dateTime="2022-05-01"></time>
                                  </div>
                                  <div className="statusActionBox">
                                    <div className="dropdown d-inlie-block">
                                      <button
                                        className="btn btnTaskStatus taskStatus_deliverables_required"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i className="bi bi-cloud-arrow-up-fill" />
                                      </button>
                                      <ul className="dropdown-menu hiddenStatus">
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_new">
                                              <i className="bi bi-app" />
                                            </span>{" "}
                                            New
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_claimed">
                                              <i className="bi bi-clock" />
                                            </span>{" "}
                                            Claimed
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_in_progress">
                                              <i className="bi bi-hourglass-split" />
                                            </span>{" "}
                                            In Process
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_pending_verification">
                                              <i className="bi bi-check2-square" />
                                            </span>{" "}
                                            Complete Pending Verification
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_revision">
                                              <i className="bi bi-exclamation-triangle-fill" />
                                            </span>{" "}
                                            Revisions
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_closed_successful">
                                              <i className="bi bi-flag-fill" />
                                            </span>{" "}
                                            Closed Successful
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_clarification_needed">
                                              <i className="bi bi-chat-fill" />
                                            </span>{" "}
                                            Specs / Clarification Needed
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_deliverables_required">
                                              <i className="bi bi-cloud-arrow-up-fill" />
                                            </span>{" "}
                                            Deliverables Required
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_prerequisite">
                                              <i className="bi bi-clock" />
                                            </span>{" "}
                                            Prerequisite / Dependency
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                    <a
                                      href="javascript:"
                                      className="btn btn-delete"
                                      data-bs-toggle="tooltip"
                                      title="Delete"
                                    >
                                      <i className="bi bi-trash-fill" />
                                    </a>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center tableColChkDel">
                                  <div className="tableColChkDelBox">
                                    <input
                                      type="checkbox"
                                      name="fileid[]"
                                      id="ID10"
                                      className="css-checkbox"
                                    />
                                    <label htmlFor="ID10" className="css-checkbox-label"></label>
                                    <span className="tableColChkSpan btn-secondary">
                                      <i className="bi bi-house-door-fill" />
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <a href="#">Task 9</a>
                                </td>
                                <td width="10%" className="d-none d-md-table-cell colTaskUrgency">
                                  <button type="button" className="btn urgencyStatus_emergency">
                                    <i className="bi bi-fire" /> Emergency!
                                  </button>
                                </td>
                                <td className="d-none d-lg-table-cell">
                                  <div className="lastActivityBox">
                                    <time className="timeago" dateTime="2022-05-01"></time>
                                  </div>
                                  <div className="statusActionBox">
                                    <div className="dropdown d-inlie-block">
                                      <button
                                        className="btn btnTaskStatus taskStatus_prerequisite"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i className="bi bi-clock" />
                                      </button>
                                      <ul className="dropdown-menu hiddenStatus">
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_new">
                                              <i className="bi bi-app" />
                                            </span>{" "}
                                            New
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_claimed">
                                              <i className="bi bi-clock" />
                                            </span>{" "}
                                            Claimed
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_in_progress">
                                              <i className="bi bi-hourglass-split" />
                                            </span>{" "}
                                            In Process
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_pending_verification">
                                              <i className="bi bi-check2-square" />
                                            </span>{" "}
                                            Complete Pending Verification
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_revision">
                                              <i className="bi bi-exclamation-triangle-fill" />
                                            </span>{" "}
                                            Revisions
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_closed_successful">
                                              <i className="bi bi-flag-fill" />
                                            </span>{" "}
                                            Closed Successful
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_clarification_needed">
                                              <i className="bi bi-chat-fill" />
                                            </span>{" "}
                                            Specs / Clarification Needed
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_deliverables_required">
                                              <i className="bi bi-cloud-arrow-up-fill" />
                                            </span>{" "}
                                            Deliverables Required
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="javascript:">
                                            <span className="taskStatus_prerequisite">
                                              <i className="bi bi-clock" />
                                            </span>{" "}
                                            Prerequisite / Dependency
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                    <a
                                      href="javascript:"
                                      className="btn btn-delete"
                                      data-bs-toggle="tooltip"
                                      title="Delete"
                                    >
                                      <i className="bi bi-trash-fill" />
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="HierarchyTreeView"
                        role="tabpanel"
                        aria-labelledby="HierarchyTreeView-tab"
                      >
                        <div className="tabContentTop">
                          <div className="panel">HierarchyTreeView</div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="KanbanPipelineView"
                        role="tabpanel"
                        aria-labelledby="KanbanPipelineView-tab"
                      >
                        <div className="tabContentTop p-0">
                          <div className="pipelineScrollView">
                            <div className="lineBox vh-100">
                              <div className="linBoxTitle taskStatus_new">
                                <i className="bi bi-app" /> New
                              </div>
                              <div className="linBoxTasksList">
                                <ul>
                                  <li>
                                    <a href="#">Task 1</a>
                                  </li>
                                  <li>
                                    <a href="#">Task 2</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="lineBox vh-100">
                              <div className="linBoxTitle taskStatus_claimed">
                                <i className="bi bi-clock" /> Claimed
                              </div>
                              <div className="linBoxTasksList">
                                <ul>
                                  <li>
                                    <a href="#">Task 3</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="lineBox vh-100">
                              <div className="linBoxTitle taskStatus_in_progress">
                                <i className="bi bi-hourglass-split" /> In Process
                              </div>
                            </div>
                            <div className="lineBox vh-100">
                              <div className="linBoxTitle taskStatus_pending_verification">
                                <i className="bi bi-check2-square" /> Complete Pending Verification
                              </div>
                              <div className="linBoxTasksList">
                                <ul>
                                  <li>
                                    <a href="#">Task 4</a>
                                  </li>
                                  <li>
                                    <a href="#">Task 5</a>
                                  </li>
                                  <li>
                                    <a href="#">Task 6</a>
                                  </li>
                                  <li>
                                    <a href="#">Task 7</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="lineBox vh-100">
                              <div className="linBoxTitle taskStatus_revision">
                                <i className="bi bi-exclamation-triangle-fill" /> Revisions
                              </div>
                              <div className="linBoxTasksList">
                                <ul>
                                  <li>
                                    <a href="#">Task 8</a>
                                  </li>
                                  <li>
                                    <a href="#">Task 9</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="MapView"
                        role="tabpanel"
                        aria-labelledby="MapView-tab"
                      >
                        <div className="tabContentTop">
                          <div className="panel">MapView</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Accordion.Body>
      </Accordion>
    </div>
  )
}

export default TaskPanel
