import React from "react"
import { Accordion } from "react-bootstrap"

const GroupMembers = () => {
  return (
    <div className="col-lg-12 mb-3" id="team_panel">
      <Accordion defaultActiveKey="0" className="accordion-item panel panel-green" alwaysOpen>
        <Accordion.Header>
          <i className="bi bi-person-fill me-2" /> People & Roles
        </Accordion.Header>
        <Accordion.Body id="team_container">
          <div className="row btnAddNewPeopleInWorkroomContainer d-none">
            <div className="col-lg-12 mb-1">
              <div className="colHeading">
                <strong>
                  <i className="bi bi-person-plus-fill" /> Add Team User
                </strong>
              </div>
              <div className="dh-block-body">
                <div className="mb-2">
                  <input type="text" className="form-control" placeholder="Any email or phone" />
                </div>
                <div className="mb-2">
                  <select className="form-select">
                    <option>== List of roles ==</option>
                  </select>
                </div>
                <div className="mb-2">
                  <div className="alert alert-danger" role="alert">
                    <i className="bi bi-exclamation-triangle-fill" /> Whoa there! You don't have any roles
                    defined yet. Why not <a href="#">click here to add some now</a>?
                  </div>
                </div>
                <div className="mb-2">
                  <div className="d-flex justify-content-between">
                    <div className="">
                      <input
                        type="checkbox"
                        name="sendInvitation"
                        id="sendInvitation"
                        className="css-checkbox"
                        checked="checked"
                      />
                      <label htmlFor="sendInvitation" className="css-checkbox-label">
                        Send Workroom Invitation
                      </label>
                    </div>
                    <div className="featuredContactBox" data-bs-toggle="tooltip" title="Featured Contact">
                      <input type="checkbox" name="featuredContact" id="featuredContact" />
                      <label htmlFor="featuredContact"></label>
                    </div>
                  </div>
                </div>
                <div className="mb-2 sendInvitationMsg">
                  <textarea className="form-control" placeholder="Optional: Add message..."></textarea>
                </div>
                <div className="text-end">
                  <button className="btn btn-default btnCloseAddNewPeopleInWorkroom">
                    <i className="bi bi-x-lg" /> Close
                  </button>
                  <button className="btn btn-primary">
                    <i className="bi bi-check2" /> Save & Invite <i className="bi bi-send-fill" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-1">
              <div className="float-end">
                <ul className="nav nav-tabs swiftCloudTabs" role="tablist">
                  <li className="nav-item">
                    <div className="btn-group btnAddNewDropdown m-0 peopleRolesAddNewDropdown" role="group">
                      <button
                        id="btnGroupDrop1"
                        type="button"
                        className="btn btn-primary dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="bi bi-plus-lg" />{" "}
                        <span className="d-none d-md-inline">Add to Workroom</span>
                      </button>
                      <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                        <li>
                          <a className="dropdown-item btnAddNewPeopleInWorkroom" href="javascript:;">
                            <i className="bi bi-person-plus-fill" /> Contact
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <i className="bi bi-table" /> Upload CSV
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-12">
              <table id="tblContacts" className="table dataTable" cellSpacing="0" width="100%">
                <thead>
                  <tr>
                    <th width="10%" className="tableColChkDel">
                      <label htmlFor="chkAll" className="css-checkbox-label"></label>
                    </th>
                    <th width="60%">
                      Name{" "}
                      <button
                        type="button"
                        className="btn btn-sm btnTHSoryByTags float-end me-3"
                        onClick="event.stopPropagation();"
                        data-bs-toggle="modal"
                        data-bs-target="#ContactsSortByTagModal"
                        title="Advanced Tag Search"
                      >
                        <span
                          data-bs-toggle="tooltip"
                          data-placement="bottom"
                          data-animation="false"
                          title="Advanced Tag Search"
                        >
                          <i className="bi bi-tag-fill" />
                        </span>
                      </button>
                    </th>
                    <th width="5%" className="d-none d-md-table-cell">
                      <i className="bi bi-person-circle" data-bs-toggle="tooltip" title="Rave Score" />
                    </th>
                    <th width="25%" className="d-none d-md-table-cell">
                      Last Activity
                    </th>
                  </tr>
                  <tr className="d-none table_action_container">
                    <td colSpan="4" className="text-left">
                      <div className="batch-action-container">
                        <button className="btn btn-trash">
                          <i className="bi bi-trash-fill" /> Move to Trash
                        </button>
                      </div>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center tableColChkDel">
                      <div className="tableColChkDelBox">
                        <input type="checkbox" name="fileid[]" id="C_ID1" className="css-checkbox" />
                        <label htmlFor="C_ID1" className="css-checkbox-label"></label>
                        <span className="tableColChkSpan btn-secondary">
                          <i className="bi bi-person-fill" />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a
                        href="contact_detail.html"
                        className="email_deliverable"
                        data-bs-toggle="tooltip"
                        title="emailaddr@domain.com - deliverable"
                      >
                        SwiftCloud Support (Transaction Coordinator)
                        <i className="bi bi-check2" />
                      </a>
                      <span className="contactListCompany">SwiftCloud</span>
                      <div className="contactListQuickTools">
                        <a
                          className="contactEmail"
                          href="#"
                          data-bs-toggle="tooltip"
                          data-placement="top"
                          title="SwiftCloudSupport@domain.com"
                        >
                          <i className="bi bi-envelope-fill" />
                        </a>
                        <a
                          className="contactPhoneSMS"
                          href="#"
                          data-bs-toggle="tooltip"
                          data-placement="top"
                          title="SMS to 9876543210"
                        >
                          <i className="bi bi-phone-fill" />
                        </a>
                        <a
                          className="contactPhoneCall"
                          href="#"
                          data-bs-toggle="tooltip"
                          data-placement="top"
                          title="Call to 9876543210"
                        >
                          <i className="bi bi-telephone-fill" />
                        </a>
                        <a
                          className="contactAddress"
                          href="#"
                          data-bs-toggle="tooltip"
                          data-placement="top"
                          title="New street"
                        >
                          <i className="bi bi-geo-alt-fill" />
                        </a>
                      </div>
                      <div className="SwiftCloudTableTags">
                        <ul>
                          <li>
                            <a href="javaScript:void(0);" data-tagname="Designer">
                              Designer
                            </a>
                          </li>
                          <li>
                            <a href="javaScript:void(0);" data-tagname="Support">
                              Support
                            </a>
                          </li>
                        </ul>
                      </div>
                    </td>
                    <td className="d-none d-md-table-cell">78</td>
                    <td className="d-none d-lg-table-cell">
                      <div className="lastActivityBox">
                        <i className="bi bi-cursor-fill" />
                        <time className="timeago" dateTime="2022-05-01"></time>
                      </div>
                      <div className="statusActionBox">
                        <span className="workroomPeopleStarToggle">
                          <input type="checkbox" id="workroomPeopleStarToggle1" />
                          <label htmlFor="workroomPeopleStarToggle1" className="checkmark"></label>
                        </span>
                        <a
                          href="#disconnectFromWorkroomModal"
                          className="btn btn-delete"
                          data-bs-toggle="modal"
                        >
                          <span data-bs-toggle="tooltip" title="Disconnect from Workroom">
                            <i className="bi bi-person-dash" />
                          </span>
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center tableColChkDel">
                      <div className="tableColChkDelBox">
                        <input type="checkbox" name="fileid[]" id="C_ID2" className="css-checkbox" />
                        <label htmlFor="C_ID2" className="css-checkbox-label"></label>
                        <span className="tableColChkSpan btn-secondary">
                          <img src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/roger.jpg`} />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a
                        href="contact_detail.html"
                        className="email_softbounce"
                        data-bs-toggle="tooltip"
                        title="emailaddr@domain.com - soft bounce"
                      >
                        Roger V. (Listing Agent) <i className="bi bi-exclamation-triangle-fill" />
                      </a>
                      <span className="contactListCompany">SwiftCloud</span>
                      <div className="contactListQuickTools">
                        <a
                          className="contactEmail"
                          href="#"
                          data-bs-toggle="tooltip"
                          data-placement="top"
                          title="Roger@domain.com"
                        >
                          <i className="bi bi-envelope-fill" />
                        </a>
                        <a
                          className="contactPhoneSMS"
                          href="#"
                          data-bs-toggle="tooltip"
                          data-placement="top"
                          title="SMS to 2684139750"
                        >
                          <i className="bi bi-phone-fill" />
                        </a>
                        <a
                          className="contactPhoneCall"
                          href="#"
                          data-bs-toggle="tooltip"
                          data-placement="top"
                          title="Call to 2684139750"
                        >
                          <i className="bi bi-telephone-fill" />
                        </a>
                        <a
                          className="contactAddress"
                          href="#"
                          data-bs-toggle="tooltip"
                          data-placement="top"
                          title="New street"
                        >
                          <i className="bi bi-geo-alt-fill" />
                        </a>
                      </div>
                      <div className="SwiftCloudTableTags">
                        <ul>
                          <li>
                            <a href="javaScript:void(0);" data-tagname="Support">
                              Support
                            </a>
                          </li>
                        </ul>
                      </div>
                    </td>
                    <td className="d-none d-md-table-cell">75</td>
                    <td className="d-none d-lg-table-cell">
                      <div className="lastActivityBox">
                        <time className="timeago" dateTime="2022-05-01"></time>
                      </div>
                      <div className="statusActionBox">
                        <span className="workroomPeopleStarToggle">
                          <input type="checkbox" id="workroomPeopleStarToggle2" />
                          <label htmlFor="workroomPeopleStarToggle2" className="checkmark"></label>
                        </span>
                        <a
                          href="#disconnectFromWorkroomModal"
                          className="btn btn-delete"
                          data-bs-toggle="modal"
                        >
                          <span data-bs-toggle="tooltip" title="Disconnect from Workroom">
                            <i className="bi bi-person-dash" />
                          </span>
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr className="email_hardbounce_row">
                    <td className="text-center tableColChkDel">
                      <div className="tableColChkDelBox">
                        <input type="checkbox" name="fileid[]" id="C_ID3" className="css-checkbox" />
                        <label htmlFor="C_ID3" className="css-checkbox-label"></label>
                        <span className="tableColChkSpan btn-secondary">
                          <i className="bi bi-person-fill" />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a
                        href="contact_detail.html"
                        className="email_hardbounce"
                        data-bs-toggle="tooltip"
                        title="emailaddr@domain.com - hard bounce"
                      >
                        Tejas H. <i className="bi bi-x-lg" />
                      </a>
                      <span className="contactListCompany">CryptousWeb</span>
                      <div className="contactListQuickTools">
                        <a
                          className="contactEmail"
                          href="#"
                          data-bs-toggle="tooltip"
                          data-placement="top"
                          title="Tejas@domain.com"
                        >
                          <i className="bi bi-envelope-fill" />
                        </a>
                        <a
                          className="contactPhoneSMS"
                          href="#"
                          data-bs-toggle="tooltip"
                          data-placement="top"
                          title="SMS to 1397268450"
                        >
                          <i className="bi bi-phone-fill" />
                        </a>
                        <a
                          className="contactPhoneCall"
                          href="#"
                          data-bs-toggle="tooltip"
                          data-placement="top"
                          title="Call to 1397268450"
                        >
                          <i className="bi bi-telephone-fill" />
                        </a>
                      </div>
                      <div className="SwiftCloudTableTags">
                        <ul>
                          <li>
                            <a href="javaScript:void(0);" data-tagname="UI/UX">
                              UI/UX
                            </a>
                          </li>
                          <li>
                            <a href="javaScript:void(0);" data-tagname="Design">
                              Design
                            </a>
                          </li>
                          <li>
                            <a href="javaScript:void(0);" data-tagname="WordPress">
                              WordPress
                            </a>
                          </li>
                        </ul>
                      </div>
                    </td>
                    <td className="d-none d-md-table-cell">15</td>
                    <td className="d-none d-lg-table-cell">
                      <div className="lastActivityBox">
                        <time className="timeago" dateTime="2022-05-01"></time>
                      </div>
                      <div className="statusActionBox">
                        <span className="workroomPeopleStarToggle">
                          <input type="checkbox" id="workroomPeopleStarToggle3" />
                          <label htmlFor="workroomPeopleStarToggle3" className="checkmark"></label>
                        </span>
                        <a href="#4" className="btn btn-delete" data-bs-toggle="modal">
                          <span data-bs-toggle="tooltip" title="Disconnect from Workroom">
                            <i className="bi bi-person-dash" />
                          </span>
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr className="email_hardbounce_row">
                    <td className="text-center tableColChkDel">
                      <div className="tableColChkDelBox">
                        <input type="checkbox" name="fileid[]" id="C_ID4" className="css-checkbox" />
                        <label htmlFor="C_ID4" className="css-checkbox-label"></label>
                        <span className="tableColChkSpan btn-secondary">
                          <i className="bi bi-person-fill" />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a
                        href="contact_detail.html"
                        className="email_complained"
                        data-bs-toggle="tooltip"
                        title="emailaddr@domain.com - complained"
                      >
                        Zyndall <i className="bi bi-x-lg" /> <i className="bi bi-exclamation-triangle-fill" />
                      </a>
                      <div className="contactListQuickTools">
                        <a
                          className="contactEmail"
                          href="#"
                          data-bs-toggle="tooltip"
                          data-placement="top"
                          title="Zyndall@domain.com"
                        >
                          <i className="bi bi-envelope-fill" />
                        </a>
                        <a
                          className="contactPhoneCall"
                          href="#"
                          data-bs-toggle="tooltip"
                          data-placement="top"
                          title="Call to 1937248605"
                        >
                          <i className="bi bi-telephone-fill" />
                        </a>
                      </div>
                      <div className="SwiftCloudTableTags">
                        <ul>
                          <li>
                            <a href="javaScript:void(0);" data-tagname="Buyer">
                              Buyer
                            </a>
                          </li>
                        </ul>
                      </div>
                    </td>
                    <td className="d-none d-md-table-cell">0</td>
                    <td className="d-none d-lg-table-cell">
                      <div className="lastActivityBox">
                        <time className="timeago" dateTime="2022-05-01"></time>
                      </div>
                      <div className="statusActionBox">
                        <span className="workroomPeopleStarToggle">
                          <input type="checkbox" id="workroomPeopleStarToggle4" />
                          <label htmlFor="workroomPeopleStarToggle4" className="checkmark"></label>
                        </span>
                        <a
                          href="#disconnectFromWorkroomModal"
                          className="btn btn-delete"
                          data-bs-toggle="modal"
                        >
                          <span data-bs-toggle="tooltip" title="Disconnect from Workroom">
                            <i className="bi bi-person-dash" />
                          </span>
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center tableColChkDel">
                      <div className="tableColChkDelBox">
                        <input type="checkbox" name="fileid[]" id="C_ID5" className="css-checkbox" />
                        <label htmlFor="C_ID5" className="css-checkbox-label"></label>
                        <span className="tableColChkSpan">
                          <img
                            src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/bachchan-amitabh-image.jpg`}
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a href="contact_detail.html">Amitabh (Home Buyer)</a>
                      {/*HIDE FROM TOP FEATURED CONTACT (USUALLY THE CLIENT)*/}
                      <span className="workroomPeopleStarToggleInline">
                        <input type="checkbox" id="workroomPeopleStarToggleInline" checked />
                        <label htmlFor="workroomPeopleStarToggleInline" className="checkmark"></label>
                      </span>
                      <div className="contactListQuickTools">
                        <a
                          className="contactEmail"
                          href="#"
                          data-bs-toggle="tooltip"
                          data-placement="top"
                          title="useremail@domain.com"
                        >
                          <i className="bi bi-envelope-fill" />
                        </a>
                      </div>
                      <div className="SwiftCloudTableTags">
                        <ul>
                          <li>
                            <a href="javaScript:void(0);" data-tagname="Actor">
                              Actor
                            </a>
                          </li>
                          <li>
                            <a href="javaScript:void(0);" data-tagname="Bollywood">
                              Bollywood
                            </a>
                          </li>
                        </ul>
                      </div>
                    </td>
                    <td className="d-none d-md-table-cell">86</td>
                    <td className="d-none d-lg-table-cell">
                      <div className="lastActivityBox">
                        <time className="timeago" dateTime="2022-05-01"></time>
                      </div>
                      <div className="statusActionBox">
                        <span className="workroomPeopleStarToggle">
                          <input type="checkbox" id="workroomPeopleStarToggle5" checked />
                          <label htmlFor="workroomPeopleStarToggle5" className="checkmark"></label>
                        </span>
                        <a
                          href="#disconnectFromWorkroomModal"
                          className="btn btn-delete"
                          data-bs-toggle="modal"
                        >
                          <span data-bs-toggle="tooltip" title="Disconnect from Workroom">
                            <i className="bi bi-person-dash" />
                          </span>
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Accordion.Body>
      </Accordion>
    </div>
  )
}

export default GroupMembers
